import axios from "axios";

export const fetchUserEmail = async () => {
  try {
    const apiUrl = process.env.REACT_APP_API
    const response = await axios.get(`${apiUrl}/taladata`)
  
    if (response.data && response.data.userEmail) {
      return response.data.userEmail;
    }
  } catch (error) {
    console.error("Failed to fetch userEmail:", error);
  }
  return "";
};
