const SearchFilterContainer = ({
  handleSearch,
  searchTerm,
  setMosCodeFilter,
  handleStateFilterChange,
  stateFilter,
  handleEmploymentTypeFilterChange,
  employmentTypeFilter,
  handleJobCategoryFilterChange,
  jobCategoryFilter,
  handleTalaMemberFilterChange,
  talaMemberFilter,
  totalJobsCount, // New
  texasJobsCount, // New
  matchingJobsCount, // New
}) => {
  // Inline styles for mobile responsiveness
  const mobileStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%", // Ensure full width on mobile
    margin: "10px 0", // Add vertical spacing between elements
  };

  return (
    <div
      className="search-filter-container"
      style={window.innerWidth <= 768 ? mobileStyle : {}}
    >
      <div
        className="search-container"
        style={window.innerWidth <= 768 ? mobileStyle : {}}
      >
        <input
          type="text"
          placeholder="Search Job Title, City, Zip Code..."
          className="search-input"
          value={searchTerm}
          onChange={handleSearch}
          style={window.innerWidth <= 768 ? mobileStyle : { width: "500px" }}
        />
        <input
          type="text"
          placeholder="Filter by MOS Code..."
          className="mos-code-input"
          onChange={(e) => setMosCodeFilter(e.target.value)}
          style={window.innerWidth <= 768 ? mobileStyle : { width: "500px" }}
        />
      </div>
      {/* Job Counter Display */}
      <div className="job-counter">
        Total Jobs: {totalJobsCount}, Texas Jobs: {texasJobsCount}, Matching
        Jobs: {matchingJobsCount}
      </div>
      {/* Filter Dropdowns */}
      <div className="filter-container">
        <select
          onChange={handleStateFilterChange}
          className="filter-select"
          value={stateFilter.toUpperCase()}
        >
          <option value="any">All States</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>

        {/* Employment Type Filter Dropdown */}
        <select
          onChange={handleEmploymentTypeFilterChange}
          className="filter-select"
          value={employmentTypeFilter}
        >
          <option value="any">Full-time & Part-time</option>
          <option value="full-time">Full-time</option>
          <option value="part-time">Part-time</option>
        </select>

        {/* Job Category Filter Dropdown */}
        <select
          onChange={handleJobCategoryFilterChange}
          className="filter-select"
          value={jobCategoryFilter}
        >
          <option value="any">Any Category</option>
          <option value="Budget and Finance">Budget and Finance</option>
          <option value="Building Maintenance">Building Maintenance</option>
          <option value="Business Leadership">Business Leadership</option>
          <option value="Administration">Administration</option>
          <option value="Clinical Professional">Clinical Professional</option>
          <option value="Culinary and Dining Services">
            Culinary and Dining Services
          </option>
          <option value="Engagement Programming">Engagement Programming</option>
          <option value="Therapy and Fitness">Therapy and Fitness</option>
          <option value="Housekeeping and Laundry">
            Housekeeping and Laundry
          </option>
          <option value="Marketing and Sales">Marketing and Sales</option>
          <option value="Professional Development Human Resource">
            Professional Development Human Resource
          </option>
          <option value="Salon and Spa Services">Salon and Spa Services</option>
        </select>

        {/* Tala Member Filter Dropdown */}
        <select
          onChange={handleTalaMemberFilterChange}
          className="filter-select"
          value={talaMemberFilter}
        >
          <option value="any">All Members</option>
          <option value="aspenwood">The Aspenwood Company</option>
          <option value="atria">Atria Senior Living</option>
          <option value="avanti">Avanti Senior Living</option>
          <option value="belmont">Belmont Village Senior Living</option>
          <option value="brookdale">Brookdale Senior Living</option>
          <option value="cardinal bay">Cardinal Bay Senior Living</option>
          <option value="civitas">Civitas Senior Living</option>
          <option value="country">Country Palace Senior Living</option>
          <option value="discovery">Discovery Senior Living</option>
          <option value="fivestar">Five Star Senior Living</option>
          <option value="frontier">Frontier Senior Living</option>
          <option value="integral senior living">Integral Senior Living</option>
          <option value="iris">Iris Memory Care</option>
          <option value="legend">Legend Senior Living</option>
          <option value="lifewell">Lifewell Senior Living</option>
          <option value="moody">Moody Neurorehabilitation Institute</option>
          <option value="morada">Morada Senior Living</option>
          <option value="mustang">Mustang Creek Estates</option>
          <option value="pegasus">Pegasus Senior Living</option>
          <option value="sagora">Sagora Senior Living</option>
          <option value="senior resource group">Senior Resource Group</option>
          <option value="sinceri">Sinceri Senior Living</option>
          <option value="silverpoint">Silverpoint Senior Living</option>
          <option value="sodalis">Sodalis Senior Living</option>
          <option value="sonida">Sonida Senior Living</option>
          <option value="stoney brook">Stoney Brook Senior Living</option>
          <option value="sunrise">Sunrise Senior Living</option>
          <option value="silverado">Silverado Senior Living</option>
          <option value="teresa's house">Teresa's House Senior Living</option>
        </select>
      </div>
    </div>
  );
};

export default SearchFilterContainer;
