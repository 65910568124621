import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "../App.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { fetchAndExportStateAgingData } from "./stateAgingData";
import { fetchAndExportPrivateAgingData } from "./privateAging";
import {fetchAndExportAPAgingData} from "./apAging.js";
import { fetchAndExportMimoData } from "./mimo";
import { fetchUserEmail} from "./userAccess";
import {logReportActivity} from "./reportActivityLog";

const FrmCorporateReport = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarFor, setCalendarFor] = useState(null); // "start" or "end"
  const [selectedReport, setSelectedReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);


   // Fetch user email on mount
   useEffect(() => {
    const loadUserEmail = async () => {
      const email = await fetchUserEmail();
      setUserEmail(email);
    };

    loadUserEmail();
  }, []);

  useEffect(() => {
    const getOwners = async () => {
      try {
        const response = await axios.get("/api/vActiveCommunities", {
          withCredentials: true,
        });
        const data = response.data;
        const uniqueOwners = [...new Set(data.map((community) => community.Owner))];
        const options = uniqueOwners.map((owner) => ({ value: owner, label: owner }));
        setOwners(options);
      } catch (error) {
        console.log(error);
      }
    };

    getOwners();
  }, []);


  const reportOptions = [
    { value: "state_aging", label: "State Aging" },
    { value: "private_aging", label: "Private Aging" },
    { value: "move_in_out", label: "Move In/Move Out" },
    { value: "ap_aging", label: "AP Aging" },
  ];

  const handleReportChange = (selectedOption) => {
    setSelectedReport(selectedOption);
  };

  const validateFields = () => {
    let errorFields = [];
    const currentDate = new Date(); // Current date without time
    currentDate.setHours(0, 0, 0, 0);

    if (!selectedReport) errorFields.push("Report Type");

    if (selectedReport?.value === "move_in_out") {
      if (!selectedStartDate) errorFields.push("Beginning Period");
      if (
        selectedStartDate &&
        selectedDate &&
        selectedDate < selectedStartDate
      ) {
        alert("Ending period cannot be earlier than beginning period.");
        return false;
      }
    }

    if (!selectedDate) {
      errorFields.push("Ending Period");
    } else if (selectedDate > currentDate) {
      // Check if selected date is in the future
      alert("Ending period cannot be a future date.");
      return false;
    }

    if (errorFields.length > 0) {
      alert(`The following fields can't be blank: ${errorFields.join(", ")}`);
      return false;
    }

    return true;
  };

  const handleProcessClick = async () => {
    if (!validateFields()) return;
    if (selectedDate && selectedReport) {
      setIsLoading(true);
      setProgressPercentage(0);
      setIsFormDisabled(true);

      const month = selectedDate.getMonth() + 1;
      const year = selectedDate.getFullYear();

      let endpoint;
      let data;

      if (selectedReport.value === "state_aging") {
        endpoint = "/api/stateagingpostreq";
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();
        data = { month, year };
      } else if (selectedReport.value === "private_aging") {
        endpoint = "/api/privateagingpostreq";
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();
        data = { month, year };
      } else if (selectedReport.value === "move_in_out") {
        endpoint = "/api/mimopostreq";
        const startDate = selectedStartDate.toISOString().split("T")[0];
        const endDate = selectedDate.toISOString().split("T")[0];
        data = { startDate, endDate };
      } else if (selectedReport.value === "ap_aging") {
        endpoint = "/api/apPostReq";
        const endingPeriod = selectedDate.toISOString().split("T")[0];
        data = {
          reportName: "AP Aging",
          endingPeriod: endingPeriod,
          owner: selectedOwner ? selectedOwner.value : ""
        };
      }

      try {
        const response = await axios.post(endpoint, data, {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressPercentage(percentCompleted);
          },
        });

        console.log(response.data);

        if (selectedReport.value === "state_aging") {
          await fetchAndExportStateAgingData(month, year);
        } else if (selectedReport.value === "private_aging") {
          await fetchAndExportPrivateAgingData(month, year);
        } else if (selectedReport.value === "move_in_out") {
          await fetchAndExportMimoData(month, year);
        } else if (selectedReport.value === "ap_aging") {
          await fetchAndExportAPAgingData(selectedOwner,selectedDate);
        }
         // Log report activity
    if (selectedReport && userEmail) {
      await logReportActivity(userEmail, selectedReport, "All Frontier Communities"); // Replace "CommunityName" with actual community name
    }
      } catch (err) {
        console.error("Error posting data:", err);
      } finally {
        setIsLoading(false);
        setIsFormDisabled(false); // Re-enable form elements
        setProgressPercentage(100); // Ensure progress is completed
      }
    } else {
      alert("Please select date and report type.");
    }
  };

  const handleCalendarChange = (newValue) => {
    if (calendarFor === "start") {
      setSelectedStartDate(newValue);
    } else if (calendarFor === "end") {
      setSelectedDate(newValue);
    }
    setShowCalendar(false);
    setCalendarFor(null);
  };

  const formatDate = (date) => {
    if (!date) return "";

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString();

    return selectedReport?.value === "move_in_out" || "ap_aging"
      ? `${month}-${day}-${year}`
      : `${month} ${year}`;
  };

  const handleInputClick = (type) => {
    setCalendarFor(type);
    setShowCalendar(!showCalendar);
  };

  return (
    <div className={`form-container ${isFormDisabled ? "disabled" : ""}`}>
      <h5 className="form-title">Corporate Reports</h5>

      {/* Rest of your UI components... */}

      {/* Loading Bar */}
      {isLoading && (
        <div className="loading-bar-container">
          <div
            className="loading-bar"
            style={{ width: `${progressPercentage}%` }}
          ></div>
          <span>{progressPercentage}%</span>
        </div>
      )}
      <div className={`form-element ${isFormDisabled ? "disabled" : ""}`}>
        <label htmlFor="select-report">Select Corporate Report</label>
        <Select
          id="select-report"
          options={reportOptions}
          onChange={handleReportChange}
          placeholder="Select report"
        />
      </div>
      {/* Conditional Ownership Group dropdown for AP Aging */}
      {selectedReport?.value === "ap_aging" && (
        <div className="form-element">
          <label htmlFor="ownership-group">Ownership Group</label>
          <Select
            id="ownership-group"
            options={owners}
            onChange={(selectedOption) => setSelectedOwner(selectedOption)}
            placeholder="Select Ownership Group"
            isDisabled={isFormDisabled}
          />
        </div>
      )}

      {selectedReport?.value === "move_in_out" && (
        <div className="form-element">
          <label htmlFor="starting-period">Beginning Period</label>
          <input
            type="text"
            id="starting-period"
            className="input"
            value={formatDate(selectedStartDate)}
            onClick={() => handleInputClick("start")}
            readOnly
          />
          {showCalendar && calendarFor === "start" && (
            <Calendar
              onChange={handleCalendarChange}
              value={selectedStartDate}
            />
          )}
        </div>
      )}

      <div className="form-element">
        <label htmlFor="closing-period">Ending Period</label>
        <input
          type="text"
          id="closing-period"
          className="input"
          value={formatDate(selectedDate)}
          onClick={() => handleInputClick("end")}
          readOnly
        />
        {showCalendar && calendarFor === "end" && (
          <Calendar
            onChange={handleCalendarChange}
            value={selectedDate}
            view={selectedReport?.value === "move_in_out" || "ap_aging" ? "month" : "year"}
            maxDetail={
              selectedReport?.value === "move_in_out" || "ap_aging" ? "month" : "year"
            }
            minDetail="year"
          />
        )}
      </div>

      <div className="form-element button-container">
        <button className="half-width-button" onClick={handleProcessClick}>
          Process
        </button>
        {/*<button className="half-width-button">Return to Menu</button>*/}
      </div>
    </div>
  );
};

export default FrmCorporateReport;