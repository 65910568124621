import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const getBrowserDetails = () => {
  const userAgent = navigator.userAgent;
  let browserName = 'Unknown';
  let browserVersion = 'Unknown';
  let osName = 'Unknown';
  const plugins = [...navigator.plugins].map(plugin => plugin.name).join(', ');
  const headers = JSON.stringify({
    'User-Agent': navigator.userAgent,
    'Accept-Language': navigator.language
  });

  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
    browserVersion = userAgent.match(/Firefox\/(\d+)/)[1];
  } else if (userAgent.indexOf("Edg") > -1) {
    browserName = "Microsoft Edge";
    browserVersion = userAgent.match(/Edg\/(\d+)/)[1];
  } else if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Google Chrome";
    browserVersion = userAgent.match(/Chrome\/(\d+)/)[1];
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
    browserVersion = userAgent.match(/Version\/(\d+)/)[1];
  } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
    browserName = "Microsoft Internet Explorer";
    browserVersion = userAgent.match(/(?:MSIE |rv:)(\d+)/)[1];
  } else if (userAgent.indexOf("Opera") > -1) {
    browserName = "Opera";
    browserVersion = userAgent.match(/Opera\/(\d+)/)[1];
  }

  if (navigator.appVersion.indexOf("Win") !== -1) osName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) osName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) osName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) osName = "Linux";

  return { browserName, browserVersion, osName, plugins, headers };
};

const getSessionId = () => {
  let sessionId = localStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
};

const logVisit = async (jobId) => {
  const { browserName, browserVersion, osName, plugins, headers } = getBrowserDetails();
  const timeOfVisit = new Date().toISOString();
  const durationOfVisit = Math.floor((performance.now() / 1000)); // duration in seconds
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const sessionId = getSessionId();

  try {
    // Fetch job data
    const apiUrl = process.env.REACT_APP_API;
    const response = await axios.get(`${apiUrl}/taladata`);
    const jobData = response.data;

    // Find the job with the given ID
    const job = jobData.find(job => job.indexCol === jobId);
    if (!job) {
      console.error(`Job with ID ${jobId} not found`);
      return;
    }

    // Fetch IP address and geographic region
    const ipResponse = await axios.get('https://ipapi.co/json/');
    const { ip, city, region, country } = ipResponse.data;

    const visitData = {
      browserName,
      browserVersion,
      osName,
      timeOfVisit,
      jobPostUrlClicked: job.JobPostURL,
      talaJobUrl: `${window.location.origin}/tala.org/job-posting?jobId=${jobId}`,
      durationOfVisit,
      ipAddress: ip,
      timezone,
      geographicRegion: `${city}, ${region}, ${country}`,
      plugins,
      headers,
      sessionId
    };

    console.log('Data to be sent:', visitData); // Log the data to be sent

    await axios.post(`${apiUrl}/visitlog`, visitData);
    console.log('Visit logged successfully');
  } catch (error) {
    console.error('Error logging visit:', error);
  }
};

export default logVisit;
