import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TalaLandingPage.css";
import talaLogo from "./tala-logo.png";

// Utility function to truncate text with null check
const truncateText = (text, maxLength) => {
  // If text is null or undefined, return an empty string or a placeholder
  if (!text) return "Description not available.";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

// Updated JobListing component to use the truncateText function
const JobListing = ({ data }) => (
  <div className="job-listing">
    <h2>{data.JobTitle}</h2>
    <p>{`${data.StreetAddress}, ${data.City}, ${data.State}, ${data.ZipCode}`}</p>
    <h3>{data.CompanyName}</h3>
    <div
      dangerouslySetInnerHTML={{
        __html: truncateText(data.JobDescription, 200),
      }}
    />
    <a href={data.JobPostURL} target="_blank" rel="noopener noreferrer">
      Apply Now
    </a>
    <p>Type: {data.EmploymentType}</p>
  </div>
);

// TalaLandingPage component
const TalaLandingPage = () => {
  const [jobListingsData, setJobListingsData] = useState([]);
  const [totalJobsCount, setTotalJobsCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 9;
  const [noResults, setNoResults] = useState(false);

  // Fetch data from API
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API;
    axios
      .get(`${apiUrl}/taladata`)
      .then((response) => {
        setJobListingsData(response.data);
        setTotalJobsCount(response.data.length); 
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  // Safe string check function
  const safeStringCheck = (str, term) => {
    return str ? str.toLowerCase().includes(term.toLowerCase()) : false;
  };

  // Filter jobs based on search term
  const filteredJobs = jobListingsData.filter(
    (job) =>
      safeStringCheck(job.JobTitle, searchTerm) ||
      safeStringCheck(job.City, searchTerm) ||
      safeStringCheck(job.EmploymentType, searchTerm) ||
      safeStringCheck(job.CompanyName, searchTerm)
  );

  // Update noResults state
  useEffect(() => {
    setNoResults(filteredJobs.length === 0);
  }, [filteredJobs]);
  // Pagination logic
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

  // Page numbers for pagination
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Define the number of pages you want to show in the pagination bar
  const pageNumbersToShow = 5;
  const pageWindow = Math.floor(pageNumbersToShow / 2);

  // Calculate the range of pages to be shown
  const firstPageIndex = Math.max(currentPage - pageWindow, 1);
  const lastPageIndex = Math.min(
    firstPageIndex + pageNumbersToShow - 1,
    totalPages
  );

  // Update the pageNumbers array to only include the pages within the range
  const visiblePageNumbers = Array.from(
    { length: lastPageIndex - firstPageIndex + 1 },
    (_, i) => i + firstPageIndex
  );

  return (
    <div className="tala-landing-page">
    <header className="header">
      <img src={talaLogo} alt="TALA Logo" className="logo" />
      <h1>Careers in Senior Living</h1>
    </header>
    <div className="search-container">
      <input
        type="text"
        placeholder="Search jobs..."
        className="search-input"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
    <p className="total-jobs-text">Over {totalJobsCount} jobs available in our database</p>
    {noResults ? (
      <p>No results found</p>
    ) : (
      <div className="job-listings-container">
        {currentJobs.map((job, index) => (
          <JobListing key={index} data={job} />
        ))}
      </div>
    )}
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={() => setCurrentPage(currentPage - 1)}>
            Previous
          </button>
        )}
        {visiblePageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={currentPage === number ? "active" : ""}
          >
            {number}
          </button>
        ))}
        {currentPage < totalPages && (
          <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
        )}
      </div>
    </div>
  );
};

export default TalaLandingPage;