import React from "react";
import "../App.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { fetchAndExportBalanceSheetChangeData } from "./balanceSheetChangeData";
import { fetchAndExportCashFlowData } from "./cashFlowData";
import { fetchAndExportYTDTrialBalanceData } from "./YTDTrialBalanceData";
import { fetchAndExportTrialBalanceData } from "./TrialBalanceData";
import { fetchAndExportBalanceSheetData } from "./balanceSheetData";
import { fetchAndExportCurrentPriorDetailData } from "./currentPriorDetailData";
import { fetchAndExportBudgetVarianceDetailData } from "./budgetVarianceDetail";
import { fetchAndExportBudgetVarianceSummaryData } from "./budgetVarianceSummary";
import { fetchAndExportCurrentPriorSummaryData } from "./currentPriorSummary";
import { fetchAndExportOperationDetailData } from "./OperationsDetail.js";
import { fetchAndExportOperationSummaryData } from "./OperationsSummary";
import { fetchAndExportLenderSummaryData } from "./lenderSummary";
import { fetchAndExportLenderDetailData } from "./lenderDetail";
import { fetchAndExportIncomeStatementData } from "./incomeStatement";
import { fetchAndExportConsolidatedReportData } from "./consolidated";
import { fetchAndExportBalanceSheetDetail } from "./balanceSheetDetail";
import { fetchAndExportTTMRamOccupancyData } from "./ttmRamOccupancy";
import { fetchAndExportRentRollData } from "./rentroll.js";

const FrmQuickIncome = () => {
  // state variables to store the selected date and show/hide the calendar component
  const [value, setValue] = useState(null);
  const [timePeriod, setTimePeriod] = useState("Month");
  const shouldShowTimePeriod = [
    "March",
    "June",
    "September",
    "December",
  ].includes(value?.toLocaleString("default", { month: "long" }));
  const [showCalendar1, setShowCalendar1] = useState(false);
  const [owners, setOwners] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const [includePriorOwners, setIncludePriorOwners] = useState(false);
  const [originalSelectedCommunities, setOriginalSelectedCommunities] =
    useState([]);
  const [reportOptions, setReportOptions] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isConsolidatedReportDownloading, setIsConsolidatedReportDownloading] =
    useState(false);
  const [showDownloadNotification, setShowDownloadNotification] =
    useState(false);
  const [showEndingMonth, setShowEndingMonth] = useState(true);

  // Fetch the userEmail
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API
        const response = await axios.get(`${apiUrl}/user-access`) 
        if (response.data && response.data.userEmail) {
          setUserEmail(response.data.userEmail);
        }
      } catch (error) {
        console.error("Failed to fetch userEmail:", error);
      }
    };

    fetchUserEmail();
  }, []);

  // fetch owners data from the server and set it in the state
  useEffect(() => {
    const getOwners = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API
        const response = await axios.get(`${apiUrl}/vActiveCommunities`, { withCredentials: true,});
        

        const data = response.data;

        // Extract unique owners from the data
        const uniqueOwners = [
          ...new Set(data.map((community) => community.Owner)),
        ];

        const options = uniqueOwners.map((owner) => {
          return {
            value: owner,
            label: owner,
          };
        });

        setOwners(options);
      } catch (error) {
        console.log(error);
      }
    };

    getOwners();
  }, []);

  const validateFields = () => {
    // Check if the report is not selected
    if (!selectedReport) {
      alert("Report can't be empty");
      return false;
    }

    // Skip the ending month validation for "Financial Rent Roll"
    if (selectedReport.label !== "Financial Rent Roll") {
      // Check if the date is not selected or if it is a future date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset hours to start of the day for comparison
      if (!value) {
        alert("Ending Month can't be empty");
        return false;
      } else if (value > today) {
        alert("Ending Month can't be in the future");
        return false;
      }
    }

    // Check if the owner is not selected
    if (!selectedOwner && selectedReport.label !== "Financial Rent Roll") {
      alert("Owner can't be empty");
      return false;
    }

    // Check if the communities are not selected
    if (
      (!selectedCommunities || selectedCommunities.length === 0) &&
      selectedReport.label !== "Financial Rent Roll"
    ) {
      alert("Select Community(ies) can't be empty");
      return false;
    }

    return true;
  };

  const getCommunities = async () => {
    try {
      
      const apiUrl = process.env.REACT_APP_API
      const response = await axios.get(`${apiUrl}/vActiveCommunities`, { withCredentials: true,});
      const data = response.data;

      const options = data.map((communities) => {
        return {
          value: communities.CommunityNum,
          label: `${communities.CommunityNum} - ${communities.CommunityShortName}`,
          owner: communities.Owner, // Add the owner property here
        };
      });

      // add Select All option to communities
      options.unshift({ value: "all", label: "Select All Communities" });

      setCommunities(options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommunities();
  }, []);

  const DownloadNotification = () => (
    <div className="download-notification">
      Your report is being downloaded. Please wait...
    </div>
  );

  const handleProcessClick = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    const selectedDate = value || new Date();
    const reportMonth = selectedDate.getMonth() + 1;
    const reportYear = selectedDate.getFullYear();

    console.log("Selected Date:", selectedDate); // Check the selected date
    console.log("Report Month:", reportMonth); // Check the report month
    console.log("Report Year:", reportYear); // Check the report year

    const gpNum = selectedCommunities[0];
    const communityName = selectedCommunities
      .map(
        (community) =>
          filteredCommunities.find((option) => option.value === community).label
      )
      .join(", ");

    let apiEndpoint;
    let postData = {
      "@GPNum": selectedCommunities,
      "@ReportMonth": reportMonth, // This now correctly represents the human-readable month
      "@ReportYr": reportYear,
      "@Community": communityName,
      "@TimePeriod": timePeriod,
      "@ReportName": selectedReport.label,
    };

    setIsFormDisabled(true);
    setIsLoading(true);

    if (
      selectedReport.label === "Consolidated Report" ||
      selectedReport.label === "TTM RAM Occupancy"
    ) {
      setIsConsolidatedReportDownloading(true);
      setShowDownloadNotification(true); // Show download notification
    }
   
    if (selectedReport.label === "Statement of Cash Flows") {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/cashflowspostreq`;
    } else if (
      selectedReport.label === "Balance Sheet Change" ||
      selectedReport.label === "Balance Sheet" ||
      selectedReport.label === "Balance Sheet Detail"
    ) {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/quickreportspostreq`;
    } else if (selectedReport.label === "Financial Rent Roll") {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/rentrollpostreq`;
      postData = {
        "@GPNum": selectedCommunities,
      };
    } else if (selectedReport.label === "YTD Trial Balance") {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/ytdtrialbalancepostreq`;
    } else if (selectedReport.label === "Trial Balance") {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/ytdtrialbalancepostreq`;
    } else if (
      selectedReport.label === "Current Prior Detail" ||
      selectedReport.label === "Current Prior Summary"
    ) {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/currentprior`;
    } else if (
      selectedReport.label === "Budget Variance Detail" ||
      selectedReport.label === "Budget Variance Summary"
    ) {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/budgetvariancedetailpostreq`;
    } else if (
      selectedReport.label === "Lender Report Detail" ||
      selectedReport.label === "Lender Report Summary"
    ) {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/lenderreportpostreq`;
    } else if (selectedReport.label === "Income Statement Change") {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/incomestatementpostreq"`;
    } else if (selectedReport.label === "Consolidated Report") {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/consolidatedreportpostreq"`;
    } else if (selectedReport.label === "TTM RAM Occupancy") {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/ttmramoccupancypostreq"`;
    } else if (
      selectedReport.label === "Statement of Operations Detail" ||
      selectedReport.label === "Statement of Operations Summary"
    ) {
      const apiUrl = process.env.REACT_APP_API
      apiEndpoint = `${apiUrl}/statementofoperationspostreq"`;
    } else {
      // If you don't want to make an API call for other report types, return early
      return;
    }

    postData = {
      //"@Period": value.toISOString(),
      "@GPNum": selectedCommunities,
      "@ReportMonth": reportMonth,
      "@ReportYr": reportYear,
      "@Community": communityName,
      "@TimePeriod": timePeriod,
    };
    if (["TTM RAM Occupancy"].includes(selectedReport.label)) {
      postData["@Period"] = value.toISOString();
    }
    if (
      [
        "Balance Sheet Change",
        "Balance Sheet",
        "Balance Sheet Detail",
      ].includes(selectedReport.label)
    ) {
      postData["@Condition"] = 18;
    }

    postData["@ReportName"] = selectedReport["label"];

    console.log("postData", postData);

    setIsFormDisabled(true); // Disable the form when report generation starts
    setIsLoading(true);
    setProgressPercentage(0);
    if (
      selectedReport.label === "Consolidated Report" ||
      selectedReport.label === "TTM RAM Occupancy"
    ) {
      setIsConsolidatedReportDownloading(true); // Start tracking download for consolidated report
    }

    let simulatedProgress = 0;

    const progressSimulation = setInterval(() => {
      simulatedProgress += 1;
      if (simulatedProgress <= 95) {
        setProgressPercentage(simulatedProgress);
      } else {
        clearInterval(progressSimulation);
      }
    }, 100);

    try {
      await axios.post(apiEndpoint, postData, {
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted >= 95) {
            clearInterval(progressSimulation);
          }
          setProgressPercentage(percentCompleted);
        },
      });

      const fetchResponse = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (fetchResponse.ok) {
        console.log("API call succeeded");

        if (selectedReport.label === "Balance Sheet Change") {
          fetchAndExportBalanceSheetChangeData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Statement of Cash Flows") {
          fetchAndExportCashFlowData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "YTD Trial Balance") {
          fetchAndExportYTDTrialBalanceData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Trial Balance") {
          fetchAndExportTrialBalanceData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Balance Sheet Detail") {
          fetchAndExportBalanceSheetDetail(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Balance Sheet") {
          fetchAndExportBalanceSheetData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Current Prior Detail") {
          fetchAndExportCurrentPriorDetailData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Current Prior Summary") {
          fetchAndExportCurrentPriorSummaryData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Budget Variance Detail") {
          fetchAndExportBudgetVarianceDetailData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Budget Variance Summary") {
          fetchAndExportBudgetVarianceSummaryData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Financial Rent Roll") {
          fetchAndExportRentRollData(reportMonth, reportYear);
        } else if (selectedReport.label === "Lender Report Summary") {
          fetchAndExportLenderSummaryData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Lender Report Detail") {
          fetchAndExportLenderDetailData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Statement of Operations Detail") {
          fetchAndExportOperationDetailData(
            reportMonth,
            reportYear,
            gpNum,
            communityName,
            timePeriod
          );
        } else if (selectedReport.label === "Income Statement Change") {
          fetchAndExportIncomeStatementData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          );
        } else if (selectedReport.label === "Consolidated Report") {
          fetchAndExportConsolidatedReportData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          ).then(() => {
            setIsConsolidatedReportDownloading(false);
            setShowDownloadNotification(false); // Notify user when download is complete
            setIsFormDisabled(false);
            setIsLoading(false);
          });
        } else if (selectedReport.label === "TTM RAM Occupancy") {
          fetchAndExportTTMRamOccupancyData(
            reportMonth,
            reportYear,
            gpNum,
            communityName
          ).then(() => {
            setIsConsolidatedReportDownloading(false);
            setShowDownloadNotification(false); // Notify user when download is complete
            setIsFormDisabled(false);
            setIsLoading(false);
          });
        } else if (selectedReport.label === "Statement of Operations Summary") {
          fetchAndExportOperationSummaryData(
            reportMonth,
            reportYear,
            gpNum,
            communityName,
            timePeriod
          );
        }
      } else {
        console.error("API call failed with status:", fetchResponse.status);
      }
    } catch (error) {
      clearInterval(progressSimulation);
      console.error("API call failed with error:", error);
    }
    // After a report is generated and before the  post log data to the new endpoint
    try {
      const activityLogData = {
        userEmail: userEmail,
        reportName: selectedReport.label,
        communityName: communityName,
        activityType: "Report Generated",
        activityTime: new Date().toISOString(),
      };
      const apiUrl = process.env.REACT_APP_API
      await axios.post(
        `${apiUrl}/report-activity-log`,
        activityLogData
      );
    } catch (error) {
      console.error("Failed to log report activity:", error);
    } finally {
      setIsFormDisabled(false);
      setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  const handleReportChange = (selectedOption) => {
    setSelectedReport(selectedOption);
    setShowEndingMonth(selectedOption.label !== "Financial Rent Roll");
  };

  useEffect(() => {
    // Reset form disabled/loading state only if the consolidated report download is not in progress
    if (!isConsolidatedReportDownloading && isLoading) {
      setIsFormDisabled(false);
      setIsLoading(false);
    }
  }, [isConsolidatedReportDownloading, isLoading]);

  const fetchReportOptions = async () => {
    
    try {
      const apiUrl = process.env.REACT_APP_API
      const response = await axios.get(
        `${apiUrl}/vQuickISReportOptions`,
        {
          withCredentials: true,
        }
      );

      const data = response.data;
      console.log("Fetched data:", data);

      const formattedData = data.map((item) => ({
        value: item.Ord,
        label: item.ReportOption,
      }));
      // add the extra option
      formattedData.push({ value: 16, label: "Consolidated Report" });
      formattedData.push({ value: 18, label: "Financial Rent Roll" });
      setReportOptions(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReportOptions();
  }, []);
  useEffect(() => {
    const selectedMonth = value?.getMonth();
    if (
      selectedMonth !== 2 && // March (months are 0 indexed in JS)
      selectedMonth !== 5 && // June
      selectedMonth !== 8 && // September
      selectedMonth !== 11 // December
    ) {
      setTimePeriod("Month");
    }
  }, [value]);

  const handleOwnerChange = async (selectedOption) => {
    setSelectedOwner(selectedOption.value);

    // Filter communities based on selected owner and includePriorOwners state
    const filteredOptions = communities.filter((community) => {
      const isSelectedOwner = community.owner === selectedOption.value;
      const isSelectAll = community.value === "all";
      const hasPriorOwner = community.label
        .toLowerCase()
        .includes("prior owner");

      if (isSelectedOwner || isSelectAll) {
        return includePriorOwners ? true : !hasPriorOwner;
      }

      return false;
    });

    setFilteredCommunities(filteredOptions);
    setSelectedCommunities([]);
  };
  const handleIncludePriorOwners = (event) => {
    const isChecked = event.target.checked;

    setIncludePriorOwners(isChecked);

    // Filter the available communities based on the selected owner and includePriorOwners state
    const filteredOptions = communities.filter((community) => {
      const isSelectedOwner =
        !selectedOwner || community.owner === selectedOwner;
      const isSelectAll = community.value === "all";
      const hasPriorOwner = community.label
        .toLowerCase()
        .includes("prior owner");

      if (isSelectedOwner || isSelectAll) {
        return isChecked || !hasPriorOwner;
      }

      return false;
    });

    setFilteredCommunities(filteredOptions);

    // Reset the selected communities to the original selection if the checkbox is checked again
    if (isChecked) {
      setSelectedCommunities(originalSelectedCommunities);
    } else {
      setSelectedCommunities((prevSelectedCommunities) => {
        return prevSelectedCommunities.filter(
          (value) =>
            !filteredCommunities
              .find((option) => option.value === value)
              ?.label.toLowerCase()
              .includes("prior owner")
        );
      });
    }
  };

  const handleCommunityChange = (selectedOptions) => {
    if (selectedOptions) {
      const selectedValues = selectedOptions.map((option) => option.value);
      if (selectedValues.includes("all")) {
        setSelectedCommunities(
          communities
            .filter((option) => {
              const isSelectedOwner =
                selectedOwner === null || option.owner === selectedOwner;
              const isSelectAll = option.value === "all";
              const hasPriorOwner = option.label
                .toLowerCase()
                .includes("prior owner");

              return (
                !isSelectAll &&
                isSelectedOwner &&
                (includePriorOwners ? true : !hasPriorOwner)
              );
            })
            .map((option) => option.value)
        );
      } else {
        setSelectedCommunities(
          selectedValues.filter((value) => value !== "all")
        );
      }

      // Store the original selected communities
      setOriginalSelectedCommunities(
        selectedValues.filter((value) => value !== "all")
      );

      // Filter the available communities based on the selected owner and includePriorOwners state
      const filteredOptions = communities.filter((community) => {
        const isSelectedOwner = community.owner === selectedOwner;
        const isSelectAll = community.value === "all";
        const hasPriorOwner = community.label
          .toLowerCase()
          .includes("prior owner");

        if (isSelectedOwner || isSelectAll) {
          return includePriorOwners ? true : !hasPriorOwner;
        }

        return false;
      });

      setFilteredCommunities(filteredOptions);
    } else {
      setSelectedCommunities([]);
      setFilteredCommunities(communities);
    }
  };

  return (
    // outer container for the form
    <div className={`form-container ${isFormDisabled ? "disabled" : ""}`}>
      {showDownloadNotification && <DownloadNotification />}
      {/* header for the form */}
      <h5 className="form-title">Quick Reports</h5>

      {isLoading && (
        <div className="loading-bar-container">
          <div
            className="loading-bar"
            style={{ width: `${progressPercentage}%` }}
          ></div>
          <span>{progressPercentage}%</span>
        </div>
      )}

      <form onSubmit={handleProcessClick}>
        {/* form element for selecting report*/}
        <div className="form-element">
          <label htmlFor="select-report">Report</label>
          <select
            id="select-report"
            className="select"
            onChange={(e) =>
              handleReportChange({
                value: e.target.value,
                label: e.target.options[e.target.selectedIndex].text,
              })
            }
          >
            <option value="" disabled selected hidden>
              Choose a report
            </option>
            {reportOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Conditionally render the "Ending Month" div based on the showEndingMonth state */}
        {showEndingMonth && (
          <div className="form-element">
            <label htmlFor="ending-month">Ending Month</label>
            <input
              type="text"
              id="ending-month"
              className="select"
              value={
                value
                  ? `${value.toLocaleString("default", {
                      month: "long",
                    })} - ${value.getFullYear()}`
                  : "Ending Month"
              }
              onClick={() => setShowCalendar1(!showCalendar1)}
              readOnly
            />
            {showCalendar1 && (
              <Calendar
                onChange={(newValue) => {
                  console.log("New date selected:", newValue); // This should log the correct date
                  setValue(newValue);
                  setShowCalendar1(false);
                }}
                value={value}
                view="year"
                maxDetail="year"
              />
            )}
          </div>
        )}

        {/* form element for time period*/}
        {shouldShowTimePeriod && (
          <div className="form-element">
            <label htmlFor="time-period">Time Period</label>
            <select
              id="time-period"
              className="select"
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
            >
              <option value="" disabled hidden>
                Select Time Period
              </option>
              <option value="Month">Month</option>
              {[
                value?.getMonth() === 2, // March
                value?.getMonth() === 5, // June
                value?.getMonth() === 8, // September
                value?.getMonth() === 11, // December
              ].includes(true) && <option value="Quarter">Quarter</option>}
            </select>
          </div>
        )}

        {/* form element for owners*/}
        <div className="form-element">
          <label htmlFor="select-owner">Owner</label>
          <select
            id="select-owner"
            className="select"
            onChange={(e) => handleOwnerChange({ value: e.target.value })}
          >
            <option value="" disabled selected>
              Select Owner
            </option>
            {owners.map((owner) => (
              <option value={owner.value}>{owner.label}</option>
            ))}
          </select>
        </div>

        {/* form element for communities*/}

        <div className="form-element communities-container">
          <div className="community-select">
            <label htmlFor="select-community">Select Community(ies)</label>

            {/* Add the "Include Prior Owners" checkbox and label */}
            <div
              style={{
                marginLeft: "4px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <input
                type="checkbox"
                id="include-prior-owners"
                checked={includePriorOwners}
                onChange={handleIncludePriorOwners}
              />
              <label
                htmlFor="include-prior-owners"
                style={{ marginLeft: "4px" }}
              >
                Include Prior Owners
              </label>
            </div>

            <Select
              id="community"
              options={filteredCommunities}
              isMulti
              onChange={handleCommunityChange}
              closeMenuOnSelect={false}
              placeholder="Select Community(ies)"
              value={selectedCommunities.map((community) =>
                filteredCommunities.find((option) => option.value === community)
              )}
            />
          </div>
        </div>

        {/* ... form elements ... */}
        <div className="form-element button-container">
          <button
            type="submit"
            className="half-width-button"
            onClick={handleProcessClick}
            disabled={isFormDisabled}
          >
            Process
          </button>

          {/*<button className="half-width-button">Return to Menu</button> */}
        </div>
      </form>
    </div>
  );
};

export default FrmQuickIncome;
