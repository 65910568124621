import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const fetchAndExportCurrentPriorSummaryData = async (
  reportMonth,
  reportYear,
  gpNum,
  community
) => {

  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/uspcurrentpriordetail`);
    const data = await response.json();

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Current Prior Summary');

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      'en-us',
      { month: 'long' }
    );

    const headers = [
      ` ${community}`,
      'Current Prior Summary',
      `For The ${reportMonth} Months Ending ${monthName}, ${reportYear}`,
    ];

    headers.forEach((header, index) => {
      const row = worksheet.addRow([]);
      row.height = 25;
      const cell = row.getCell(2);
      cell.value = header;
      cell.font = {
        size: index === 0 ? 16 : 14,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };
      cell.alignment = { horizontal: 'center' };

      if (index <= 2) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '013220' },
        };
      }

      worksheet.mergeCells(index + 1, 2, index + 1, 13);
    });

    // Add month and year to date headers
    const monthRow = worksheet.addRow([]);
    monthRow.getCell(2).value = monthName;
    monthRow.getCell(8).value = 'Year To Date';
    ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'].forEach(
      (col) => {
        monthRow.getCell(col).font = { bold: true };
      }
    );
    worksheet.mergeCells(5, 2, 5, 7);
    worksheet.mergeCells(5, 8, 5, 13);

    // Add column headers
    const columnHeaderRow = worksheet.addRow([
      'Account Description',
      'Actual',
      'Prior',
      'Variance',
      'Actual PRD',
      'Prior PRD',
      'YTD Actual',
      'YTD Prior',
      'YTD Variance',
      'YTD Actual PRD',
      'YTD PRIOR PRD',
    ]);

    columnHeaderRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

 // Add data rows
    data.forEach((rowData) => {
    // Ensure rowData.Subcategory exists before trying to manipulate it
    if (rowData.AccountDescription) {
      // Remove '___ ' from 'Subcategory'
      rowData.AccountDescription = rowData.AccountDescription.replace('___ ', '');
    }

    const row = worksheet.addRow(Object.values(rowData));


    // Apply currency formatting to numeric cells
    row.eachCell((cell, cellNumber) => {
      if (typeof cell.value === 'number') {
        if ([2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(cellNumber)) {
          cell.numFmt = cell.value < 0 ? '($#,##0.00_);($#,##0.00)' : '$#,##0.00';
        }
      }
    });
  });

    // Adjust column widths
    worksheet.getColumn(1).width = 50;
    Array(11).fill().forEach((_, index) => {
      worksheet.getColumn(index + 2).width = 13;
    });

    // Freeze or pin the first 6 rows (header rows)
    worksheet.views = [{ state: 'frozen', ySplit: 6 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Current_Prior_Summary.xlsx');
  } catch (error) {
    console.error('Error fetching and exporting data:', error);
  }
};
