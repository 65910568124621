import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const fetchAndExportRentRollData = async (
    reportMonth,
    reportYear,
    community,
) => {
   try {
      // Access the API URL environment variable with the correct prefix
      const apiUrl = process.env.REACT_APP_API;
      if (!apiUrl) {
        throw new Error("API URL not specified in the environment variables");
      }
  
      const response = await fetch(`${apiUrl}/rentroll`);
      const data = await response.json();

    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Data is empty or not in expected format');
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Rent Roll');

    const headers = ['Rent Roll Report', '', `Generated on: ${new Date().toLocaleString()}`, ' '];
    headers.forEach((header, index) => {
      const row = worksheet.getRow(index + 1);
      row.values = [header];
      row.font = { size: 14, bold: true };
      row.alignment = { horizontal: 'left' };
      row.height = 20;
    });
    
    // Add a Census Date header in row 2
    const censusDateHeaderRow = worksheet.getRow(2);
    const censusDateValue = data[0].CensusDate ? new Date(data[0].CensusDate) : new Date();
    censusDateHeaderRow.getCell(1).value = `Census Date: ${censusDateValue.toLocaleDateString()}`;
    censusDateHeaderRow.getCell(1).font = { size: 14, bold: true };
    censusDateHeaderRow.getCell(1).alignment = { horizontal: 'left' };
    censusDateHeaderRow.getCell(1).numFmt = 'M/D/YYYY';
    censusDateHeaderRow.height = 20;

    // Adjusting column widths based on header content
    headers.forEach((_, index) => {
      worksheet.getColumn(index + 1).width = 20;
    });

    // Set custom column headers
    const customColumnHeaders = [
      "Room#", "Room Style", "Res #", "Resident Name", "Census Status", "Primary/Secondary", 
      "Room Care Type", "Resident Medicaid Type", "Resident First Occ Date", 
      "In Date", "Out Date", "Activity", "CL", "Market Rate", "Medicaid Discount", "Rent Overrides Discount",
      "Recurring", "Care Allocation of Rent", "State Portion of Rent","Patient Portion of Rent", "Actual Rent Total",
      "Contractual Allowances", "Prorated Lost Revenue", "Community Fee","MiscRevenue","Prior Period Adjustments",
      "Total Revenue","Resident Days","Default Pay Method","Contractual Start Date","Contractual End Date",
      "In-Place Rent", "Market Rent","Diffrence", "Res Rate Lock", " ", "Bill Resident","Resp Party Name",
      "Resp Party Relationship", "Resp Party Home Phone","Resp Party Work Phone","Resp Party Address",
      "Resp Party City", "Resp Party State", "Resp Party Zipcode", "Billing Note",
    ];
    const columnHeaderRow = worksheet.getRow(5);
    columnHeaderRow.values = customColumnHeaders;
    columnHeaderRow.font = { bold: true };
    columnHeaderRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Adjust column widths for custom headers
    customColumnHeaders.forEach((_, i) => {
      worksheet.getColumn(i + 1).width = 20;
    });

    // Adding data rows starting from row 6
    data.forEach((item, index) => {
      const rowData = [
        item.trrRoomNumber, 
        item.tvrRoomStyle, 
        item.triResNum, 
        item.triFirstName ? `${item.triFirstName} ${item.triLastName}` : '', // Combining first and last names
        item.Status, 
        item.trrPrimarOr2nd === 1 ? 'Primary' : 'Secondary', // Converting to readable format
        item.tvrRoomType, 
        item.triMedicaidType, 
        item.triFirstOccupancy, 
        item.trrInDate, // In Date repeated as per instructions
        item.trrOutDate, 
        item.trrActivity, 
        item.ComparableCareLevel,
        item.trMarketRate,
        item.MedicaidDiscount,
        item.RentDiscountEveryMonth,
        item.trrRespite,
        item.CareCharge,
        item.StatePay,
        item.PatientResponsibility,
        item.ActualRent,
        item.ExtraRentRevenue,
        item.LostRent,
        item.CommunityFee,
        item.MiscRevenue,
        item.PPAdj,
        item.TotalRevenue,
        item.tcmjDays,
        item.triPaymentType,
         "",
         "",
        item.InPlaceRentNoCare,
        item.MarketRentNoCare, 
        item.NoCareDifference,
        item.triResRateLock,
        "",
        item.triBillResident,
        item.triRPName,
        item.triRPRelationship,
        item.triRPHomePhone,
        item.triRPWorkPhone,
        item.triRPAddress,
        item.triRPCity,
        item.triRPState,
        item.triRPZip,
        item.triBillingNote,
      ];
      worksheet.addRow(rowData).alignment = { vertical: 'middle', horizontal: 'left' };
    });
// Formatting date columns 'I', 'J', and 'K' and currency columns 'N' to 'AA' and 'AF' to 'AH'
worksheet.eachRow((row, rowNumber) => {
  if (rowNumber >= 6) { // Assuming data starts at row 6
    // Date format for columns I, J, K
    ['I', 'J', 'K'].forEach(columnLetter => {
      const dateCell = row.getCell(columnLetter);
      dateCell.numFmt = 'M/D/YYYY';
      // If the date is in ISO format, convert it to JavaScript Date
      if (typeof dateCell.value === 'string' && dateCell.value.includes('T')) {
        dateCell.value = new Date(dateCell.value);
      }
    });

    // Currency format for columns N to AA and AF to AH
    const currencyColumns = ['N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AF', 'AG', 'AH'];
    currencyColumns.forEach(columnLetter => {
      const currencyCell = row.getCell(columnLetter);
      currencyCell.numFmt = '"$"#,##0.00';
      // If the value is a number, ensure it is formatted as a currency
      if (typeof currencyCell.value === 'number') {
        currencyCell.value = parseFloat(currencyCell.value.toFixed(2));
      }
    });
  }
});
    // Freeze rows above data (including custom headers)
    worksheet.views = [{ state: 'frozen', ySplit: 5 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Rent_Roll_Report.xlsx');
  } catch (error) {
    console.error('Error fetching and exporting rent roll data:', error);
  }
};
