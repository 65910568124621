import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const glActivityData = async (communities, value, value2, originalSelectedCommunities) => {

          try {
            // Access the API URL environment variable with the correct prefix
            const apiUrl = process.env.REACT_APP_API;
            if (!apiUrl) {
              throw new Error("API URL not specified in the environment variables");
            }
        
            const response = await fetch(`${apiUrl}/glactivity`);
            const data = await response.json();
      
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("GL Detailed Activity");
    
          // Extract the community labels corresponding to the selected community values.
          const selectedCommunityLabels = originalSelectedCommunities.map(communityNum => {
            const foundCommunity = communities.find(community => community.value === communityNum);
            return foundCommunity ? ` ${foundCommunity.label}` : null;
          }).filter(Boolean); // filter out any nulls
    
          const header1 = "GL Detailed Activity Report";
          const header2 = `For ${selectedCommunityLabels.join(', ')}`;
          const header3 = `Posted between ${value.toLocaleDateString()} - ${value2.toLocaleDateString()}`;
          const mergedHeaders = [header1, header2, header3];
      
          for (let R = 0; R < 3; ++R) {
            const row = worksheet.getRow(R + 1);
            row.height = 25;
            row.getCell(1).value = mergedHeaders[R];
            row.getCell(1).font = {
              size: R === 0 ? 16 : 14,
              bold: true,
              color: { argb: "FFFFFFFF" },
            };
            row.getCell(1).alignment = { horizontal: "center" };
            row.getCell(1).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "0077B6" },
            };
            worksheet.mergeCells(R + 1, 1, R + 1, 8);
          }
      
          // Add the column headers at row 5
          if (data.length > 0) {
            const headers = Object.keys(data[0]);
            headers.forEach((header, idx) => {
              worksheet.getRow(5).getCell(idx + 1).value = header;
              worksheet.getRow(5).getCell(idx + 1).font = {
                bold: true,
                color: { argb: "0077B6" },
              };
            });
      
            // Bind the pulled data starting from row 6
            const j = 9; // Set this to the column index for "Amount", which is the 10th column in 0-based index
            data.forEach((rowData, rowIdx) => {
              const row = worksheet.getRow(rowIdx + 6);
              Object.values(rowData).forEach((value, colIdx) => {
                if (colIdx === j && typeof value === "number") {
                  // Apply the currency format and handle negative numbers
                  row.getCell(colIdx + 1).numFmt = "$#,##0.00";
                  // If the value is negative, take its absolute value to remove the minus sign
                  row.getCell(colIdx + 1).value =
                    value < 0 ? Math.abs(value) : value;
                } else {
                  row.getCell(colIdx + 1).value = value;
                }
              });
            });
          } else {
            worksheet.getRow(5).getCell(1).value = "No data returned";
          }
      
          // Freeze the header at row 5
          worksheet.views = [
            { state: 'frozen', ySplit: 5 }
          ];
      
          // Adjust column widths
          worksheet.columns = [
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
          ];
          if ("") {
            // Convert the workbook to a base64 string.
            const base64 = await workbook.xlsx.writeBuffer({ base64: true });
    
            // Create a data URI from the base64 string.
            const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
    
            // Open the data URI in a new browser tab.
            window.open(dataURI, "_blank");
          } else {
            const buffer = await workbook.xlsx.writeBuffer({
              onUpdate: (progress) => {},
            });
            const blob = new Blob([buffer], { type: "application/octet-stream" });
            saveAs(blob, "GL_Detailed_Activity.xlsx");
          }
    
         
        } catch (error) {
          console.error(error);
        }
      };
      
