import axios from "axios";

export const logReportActivity = async (userEmail, selectedReport, communityName) => {
  try {
    const activityLogData = {
      userEmail: userEmail,
      reportName: selectedReport.label,
      communityName: communityName,
      activityType: "Report Generated",
      activityTime: new Date().toISOString(),
    };

    await axios.post(
      "/api/report-activity-log",
      activityLogData
    );
  } catch (error) {
    console.error("Failed to log report activity:", error);
  }
};
