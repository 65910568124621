import React from "react";
import "../App.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        &copy; Safe Harbor Solutions LLC
      </p>
    </footer>
  );
};

export default Footer;
