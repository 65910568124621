import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const fetchAndExportOperationSummaryData = async (
  reportMonth,
  reportYear,
  gpNum,
  community,
  timePeriod
) => {
  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/uspstatementofoperations`);
    const data = await response.json();
  

    if (!Array.isArray(data) || data.length === 0) {
      throw new Error("Data is empty or not in expected format");
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Statement Of Operations Summary");

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      "en-us",
      { month: "long" }
    );

    const headers = [
      ` ${community}`,
      "Statement Of Operations Summary",
      `For The 12 Months Ending ${monthName}, ${reportYear}`,
    ];

    headers.forEach((header, index) => {
      const row = worksheet.addRow([]);
      row.height = 25;
      const cell = row.getCell(2);
      cell.value = header;
      cell.font = {
        size: index === 0 ? 16 : 14,
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
      cell.alignment = { horizontal: "center" };

      if (index <= 2) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "013220" },
        };
      }

      worksheet.mergeCells(index + 1, 2, index + 1, 13);
    });

    // Dynamically setting the column headers from the API data
    const columnHeaderData = Object.keys(data[0]);

    // Add column headers
    const columnHeaderRow = worksheet.addRow(columnHeaderData);
    columnHeaderRow.eachCell((cell, colNumber) => {
      // Set headers for columns O, P, and Q to blank
      if (colNumber >= 15 && colNumber <= 17) {
        cell.value = null;
      }

      // If timePeriod is quarter, set headers for columns D, E, and F to blank
      if (timePeriod === "Quarter" && colNumber >= 4 && colNumber <= 6) {
        cell.value = null;
      }

      cell.font = { bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add data rows
    data.forEach((rowData) => {
      const row = worksheet.addRow(Object.values(rowData));

      // Apply formatting to numeric cells
      row.eachCell((cell, colNumber) => {
        // Leave columns O, P, and Q blank
        if (colNumber >= 15 && colNumber <= 17) {
          cell.value = null;
          return;
        }
        // If timePeriod is 'Quarter', set cells in columns D, E, and F to blank
        if (timePeriod === "Quarter" && colNumber >= 4 && colNumber <= 6) {
          cell.value = null;
          return; // Skip further processing for these cells
        }

        if (typeof cell.value === "number") {
          // Hide decimal numbers
          const valueWithoutDecimal = Math.floor(cell.value);
          cell.value =
            cell.value < 0 ? -valueWithoutDecimal : valueWithoutDecimal;
          cell.numFmt =
            cell.value < 0 ? "($#,##0);($#,##0)" : "$#,##0;[Red]$-#,##0";
        }
      });

      // Check for 'ExcelFormattingCode': 'Line' and make modifications
      if (rowData["ExcelFormattingCode"] === "Line") {
        row.eachCell((cell) => {
          cell.font = { bold: true }; // Bold the entire row
          cell.border = {
            // Bold cell lines
            top: { style: "thick" },
            left: { style: "thick" },
            bottom: { style: "thick" },
            right: { style: "thick" },
          };
        });
      }
    });

    // Adjust column widths
    worksheet.getColumn(1).width = 23;
    Array.from({ length: columnHeaderData.length + 1 }).forEach((_, index) => {
      worksheet.getColumn(index + 2).width = 12;
    });

    // Freeze or pin the first 4 rows (header rows)
    worksheet.views = [{ state: "frozen", ySplit: 4 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Statement_Of_Operations_Summary.xlsx");
  } catch (error) {
    console.error("Error fetching and exporting data:", error);
  }
};
