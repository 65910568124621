import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const fetchAndExportPrivateAgingData = async (reportMonth, reportYear) => {

  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/privateaging`);
    const data = await response.json();
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Private Aging Report (Cross Company)');

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      'en-us',
      { month: 'long' }
    );

    const todayDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    const headers = [
      'Private Aging Report (Cross Company)',
      `Period: ${monthName}, ${reportYear}`,
      `Exported: ${todayDate}`
    ];

    headers.forEach((header, index) => {
      const row = worksheet.addRow([]);
      row.height = 25;
      const cell = row.getCell(2);
      cell.value = header;
      cell.font = {
        size: 14,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };
      cell.alignment = { horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '013220' },
      };
      worksheet.mergeCells(index + 1, 2, index + 1, 10); 
    });

    // Add column headers
    const columnHeaderRow = worksheet.addRow([
      'Community',
      'Resident',
      'Total',
      '<30 Jan',
      '31-60 Dec',
      '61-90 Nov',
      '91-120 Oct',
      'Over 120 Sep',
      'Status'
    ]);

    columnHeaderRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add data rows
    data.forEach((rowData) => {
      const row = worksheet.addRow(Object.values(rowData));
  
      // Apply currency formatting to numeric cells (from 3rd to 8th cell as they represent amounts)
      row.eachCell((cell, cellNumber) => {
        if (cellNumber >= 3 && cellNumber <= 8 && typeof cell.value === 'number') {
          cell.numFmt = cell.value < 0 ? '($#,##0.00_);($#,##0.00)' : '$#,##0.00';
        }
      });
    });

    // Adjust column widths
    ['Community', 'Resident', 'Total', '<30 Jan', '31-60 Dec', '61-90 Nov', '91-120 Oct', 'Over 120 Sep', 'Status']
      .forEach((header, idx) => {
        worksheet.getColumn(idx + 1).width = header.length + 5; // +5 for padding
      });

    // Freeze the column headings
    worksheet.views = [{ state: 'frozen', ySplit: columnHeaderRow.number }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Private_Aging_Report.xlsx');
  } catch (error) {
    console.error('Error fetching and exporting private aging data:', error);
  }
};
