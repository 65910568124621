import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const cleanAndBoldRow = (worksheet) => {
  worksheet.eachRow((row) => {
    const cell = row.getCell(2);
    if (
      cell &&
      typeof cell.value === "string" &&
      cell.value.startsWith("___")
    ) {
      cell.value = cell.value.replace("___", "");
      row.eachCell((cell) => {
        cell.font = { bold: true };
      });
    }
  });
};
// Function to format specific columns in 'Move In' and 'Move Out' reports
const formatMoveInMoveOutReports = (worksheet) => {
  if (worksheet.name === "Move In" || worksheet.name === "Move Out") {
    worksheet.getColumn(3).numFmt = "0"; // Format column 'C'
    worksheet.getColumn(14).numFmt = "0"; // Format column 'N'
  }
};
// Function to format PostedDate in 'GL Detailed Activity Report' to 'YYYY-MM'
const formatPostedDateGLDetailedActivityReport = (worksheet) => {
  if (worksheet.name === "GL Detailed Activity Report") {
    worksheet.getColumn("E").eachCell((cell) => {
      if (cell.value && typeof cell.value === "string") {
        const dateParts = cell.value.split("T")[0].split("-"); // Split the date string into parts
        cell.value = `${dateParts[0]}-${dateParts[1]}`; // Reassign the cell value with formatted date
        cell.numFmt = "yyyy-mm"; // Set the number format to 'YYYY-MM'
      }
    });
  }
};

// Function to format a date column in 'GL Account Analysis Enhanced' to 'YYYY-MM'
const formatColumnGLAccountAnalysisEnhanced = (worksheet) => {
  if (worksheet.name === "GL Account Analysis Enhanced") {
    worksheet.getColumn("H").eachCell((cell) => {
      if (cell.value && typeof cell.value === "string") {
        const dateParts = cell.value.split("T")[0].split("-"); // Split the date string into parts
        cell.value = `${dateParts[0]}-${dateParts[1]}`; // Reassign the cell value with formatted date
        cell.numFmt = "yyyy-mm"; // Set the number format to 'YYYY-MM'
      }
    });
  }
};
const formatGLDetailedActivityReport = (worksheet) => {
  if (worksheet.name === "GL Detailed Activity Report") {
    // Format column 'Q' (17th column)
    worksheet.getColumn(17).numFmt = "0";

    // Format column 'C' (3rd column)
    worksheet.getColumn(3).numFmt = "0";

    // Format column 'S' (19th column)
    worksheet.getColumn(19).numFmt = "0";
  }
};

// Function to clean the 'GL Account Analysis Enhanced' worksheet
const cleanGLAccountWorksheet = (worksheet) => {
  worksheet.eachRow((row, rowNumber) => {
    // For the 'GL Account Analysis Enhanced' worksheet
    if (worksheet.name === "GL Account Analysis Enhanced") {
      const amountCell = row.getCell(5);
      // Check if the cell contains a number and remove currency formatting
      if (amountCell && typeof amountCell.value === "number") {
        amountCell.numFmt = "0";
      }
    }

    // For the 'Balance Sheet Change' worksheet
    if (worksheet.name === "Balance Sheet Change") {
      const cellA = row.getCell(2);
      if (
        cellA &&
        typeof cellA.value === "string" &&
        cellA.value.startsWith("___")
      ) {
        cellA.value = cellA.value.replace("___", "");
        row.eachCell((cell) => {
          cell.font = { bold: true };
        });
      }
    }
  });

  // If 'Balance Sheet Change' worksheet, clear all data in column 'E'
  if (worksheet.name === "Balance Sheet Change") {
    // Start clearing from row 5
    const startRowForClearing = 4;
    worksheet
      .getColumn(6)
      .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber >= startRowForClearing) {
          cell.value = null;
        }
      });
  }
};

const addHeaderRows = (worksheet, reportMonth, reportYear, communityHeader) => {
  const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
    "en-us",
    { month: "long" }
  );
  const headers = [
    `${communityHeader} - ${worksheet.name}`,
    `For The Month Ending ${monthName}, ${reportYear}`,
    " ",
  ];

  // Add header rows at the top of the worksheet
  headers.forEach((header, index) => {
    const row = worksheet.getRow(index + 1);
    row.values = [header]; // Assign the header to the first cell
    row.font = { size: 14, bold: true }; // Make the font bold
    row.alignment = { horizontal: "left" }; // Align text to the left
    row.height = 20; // Set a custom height for the header row
  });

  // Set the column widths based on the header content
  headers.forEach((header, index) => {
    worksheet.getColumn(index + 1).width = header.length + 5; // Adjust the width to fit the header content
  });
};

// Function to remove gpNum from the community name
const extractCommunityName = (communityHeader) => {
  const match = communityHeader.match(/(?:OR\d+\s+-\s+)?(.+)/i);
  return match ? match[1].trim() : communityHeader;
};

const filterDataByCommunity = (data, community, propertyName) => {
  return data.filter((row) => row && row[propertyName] === community);
};

// Function to format cells to show whole numbers without a currency symbol
const formatNumbersWithoutCurrency = (worksheet) => {
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      // Assuming the first row contains headers
      row.eachCell((cell) => {
        if (typeof cell.value === "number") {
          // Apply number formatting to display whole numbers only
          cell.numFmt = "#,##0;(#,##0)"; // Removing the $ symbol
        }
      });
    }
  });
};

// Function to format percentage for the 'Budget Variance Detail' worksheet
const formatPercentageForBudgetVarianceDetail = (worksheet) => {
  if (worksheet.name === "Budget Variance Detail") {
    // Format column 'F' and 'L' as percentages
    const percentageColumns = [6, 12];
    percentageColumns.forEach((colNumber) => {
      worksheet.getColumn(colNumber).eachCell((cell) => {
        if (typeof cell.value === "number") {
          // The "\\%" escapes the percentage sign to be treated as a string literal
          cell.numFmt = "0\\%";
        }
      });
    });
  }
};

const updateBalanceSheetChangeHeaders = (worksheet) => {
  if (worksheet.name === "Balance Sheet Change") {
    // Assuming that 'Current Month' and 'Prior Month' should replace 'C' and 'P' in the 4th row
    worksheet.getRow(4).getCell(3).value = "Current Month"; // Column 'C' heading to 'Current Month'
    worksheet.getRow(4).getCell(4).value = "Prior Month"; // Column 'D' heading to 'Prior Month'
  }
};

export const fetchAndExportConsolidatedReportData = async (
  reportMonth,
  reportYear,
  gpNum,
  communityHeader
) => {
  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/uspclosingpacket`);
    const allData = await response.json();

    // Extract the actual community name from the header
    const community = extractCommunityName(communityHeader);

    const workbook = new ExcelJS.Workbook();
    const worksheets = [
      { name: "Statement of Operations Detail", data: allData[0] },
      { name: "Budget Variance Detail", data: allData[1] },
      { name: "Balance Sheet Change", data: allData[2] },
      { name: "GL Detailed Activity Report", data: allData[3] },
      { name: "GL Account Analysis Enhanced", data: allData[4] },
      { name: "Move In", data: allData[5] },
      { name: "Move Out", data: allData[6] },
      { name: "State Aging", data: allData[7] },
      { name: "Private Aging", data: allData[8] },
      { name: "TTM Occupancy", data: allData[9] },
    ];

    worksheets.forEach(({ name, data }) => {
      const worksheet = workbook.addWorksheet(name);

      // Filter data based on worksheet name
      let filteredData;
      if (name === "Move In" || name === "Move Out") {
        filteredData = filterDataByCommunity(data, community, "FacName");
      } else if (name === "State Aging" || name === "Private Aging") {
        filteredData = filterDataByCommunity(data, community, "Community");
      } else {
        filteredData = data;
      }

      // Add custom headers
      addHeaderRows(
        worksheet,
        reportMonth,
        reportYear,
        communityHeader,
        filteredData
      );

      // If there's no data, don't try to add headers or data rows
      if (filteredData.length === 0) {
        console.warn(`No data found for ${community} in worksheet ${name}`);
        return; // Skip the rest of the processing for this worksheet
      }

      const headers = Object.keys(filteredData[0]);

      // Add column headers and style them
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true };
      });
      // Add filtered data
      filteredData.forEach((rowData) => {
        const row = worksheet.addRow(Object.values(rowData));

        // Apply number formatting for numeric cells
        row.eachCell((cell, colNumber) => {
          // Check if the current worksheet is 'GL Detailed Activity Report'
          if (worksheet.name === "GL Detailed Activity Report") {
            // Apply general number format without currency for columns 'C' and 'S' only in 'GL Detailed Activity Report'
            if (colNumber === 3 || colNumber === 19) {
              if (typeof cell.value === "number") {
                cell.numFmt = "0"; // Apply general format for numbers
              }
            } else {
              // Apply currency format for other numeric cells in 'GL Detailed Activity Report'
              if (typeof cell.value === "number") {
                cell.numFmt =
                  cell.value < 0 ? "($#,##0.00_);($#,##0.00)" : "$#,##0.00";
              }
            }
          } else {
            // For other worksheets, apply the existing currency format
            if (typeof cell.value === "number") {
              cell.numFmt =
                cell.value < 0 ? "($#,##0.00_);($#,##0.00)" : "$#,##0.00";
            }
          }
        });
      });
      formatPostedDateGLDetailedActivityReport(worksheet);
      formatColumnGLAccountAnalysisEnhanced(worksheet);
      formatNumbersWithoutCurrency(worksheet);
      formatPercentageForBudgetVarianceDetail(worksheet);
      formatMoveInMoveOutReports(worksheet);
      formatGLDetailedActivityReport(worksheet);
      updateBalanceSheetChangeHeaders(worksheet);
      // Remove '___' from cells in column A and bold the entire row
      cleanAndBoldRow(worksheet);

      // Clean GL Account Worksheet and apply bold where necessary
      cleanGLAccountWorksheet(worksheet);

      // Adjust column widths
      headers.forEach((_, index) => {
        worksheet.getColumn(index + 1).width = 15;
      });

      // Freeze headers
      worksheet.views = [{ state: "frozen", ySplit: 4 }];
    });

    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      "Consolidated_Report.xlsx"
    );
  } catch (error) {
    console.error("Error fetching and exporting data:", error);
  }
};
