import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const fetchAndExportBudgetVarianceSummaryData = async (
  reportMonth,
  reportYear,
  gpNum,
  community
) => {
  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/budgetvariancedetail`);
    const data = await response.json();
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Budget Variance Detail');

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      'en-us',
      { month: 'long' }
    );

    const headers = [
      ` ${community}`,
      "Budget Variance Summary",
      `For The ${reportMonth} Months Ending ${monthName}, ${reportYear}`,
      ' ',
    ];

    // Add header rows at the top of the worksheet
    headers.forEach((header, index) => {
      const row = worksheet.getRow(index + 1);
      row.values = [header]; // Assign the header to the first cell
      row.font = { size: 14, bold: true }; // Make the font bold
      row.alignment = { horizontal: "left" }; // Align text to the left
      row.height = 20; // Set a custom height for the header row
    });

    // Set the column widths based on the header content
    headers.forEach((header, index) => {
      worksheet.getColumn(index + 1).width = header.length + 5; // Adjust the width to fit the header content
    });

    // Add month and year to date headers
    const monthRow = worksheet.addRow([]);
    monthRow.getCell(2).value = monthName;
    monthRow.getCell(8).value = 'Year To Date';
    ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'].forEach(
      (col) => {
        monthRow.getCell(col).font = { bold: true };
      }
    );
    worksheet.mergeCells(5, 2, 5, 7);
    worksheet.mergeCells(5, 8, 5, 13);

    // Add column headers
    const columnHeaderRow = worksheet.addRow([
      'Subcategory',
      'Actual',
      'Budget',
      'Variance',
      '% Var',
      'Actual PRD',
      'Budget PRD',
      'Actual',
      'Budget',
      'Variance',
      '% Var',
      'Actual PRD',
      'Budget PRD',
    ]);

    columnHeaderRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

// Add data rows
data.forEach((rowData) => {
    const row = worksheet.addRow(Object.values(rowData));
  
  // Debugging
  console.log(`First cell value: ${row.getCell(1).value}, type: ${typeof row.getCell(1).value}`);

// Check if the first cell (Column A) is not null and starts with a hyphen
const firstCellValue = row.getCell(1).value;
if (firstCellValue && typeof firstCellValue === 'string' && firstCellValue.startsWith('___')) {
  // Remove the hyphen from the start of the string and set the cell value to the modified string
  row.getCell(1).value = firstCellValue.replace("___", "");
}

  
    // Apply currency formatting to numeric cells
    row.eachCell((cell, cellNumber) => {
      if (typeof cell.value === 'number') {
        if ([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(cellNumber)) {
          // Adjust the format here
          cell.numFmt = cell.value < 0 ? '($#,##0.00_);($#,##0.00)' : '$#,##0.00';
        }
  
        if (cellNumber === 5 || cellNumber === 11) {
          cell.numFmt = '0.00%';
        }
      }
    });
  });
  
    // Adjust column widths
    worksheet.getColumn(1).width = 30;
    Array(13).fill().forEach((_, index) => {
      worksheet.getColumn(index + 2).width = 12;
    });

    // Freeze or pin the first 6 rows (header rows)
    worksheet.views = [{ state: 'frozen', ySplit: 6 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Budget_Variance_Summary.xlsx');
  } catch (error) {
    console.error('Error fetching and exporting data:', error);
  }
};
