import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const fetchAndExportBalanceSheetChangeData = async (
  reportMonth,
  reportYear,
  gpNum,
  communityName // Assuming communityName is passed in the function
) => {
  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    // Fetching the data
    const response = await fetch(`${apiUrl}/uspbalancesheetchange`);
    const data = await response.json();

    // Initializing the workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Balance Sheet Change");

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      "en-us",
      { month: "long" }
    );

    // Setting up headers
    const headers = [
      "Balance Sheet Change",
      `${communityName}`, // Add communityName here
      `For ${reportMonth} Months Ending ${monthName}, ${reportYear}`,
      " ",
      " ",
    ];
    
    // Add header rows at the top of the worksheet
    headers.forEach((header, index) => {
      const row = worksheet.getRow(index + 1);
      row.values = [header]; // Assign the header to the first cell
      row.font = { size: 14, bold: true }; // Make the font bold
      row.alignment = { horizontal: "left" }; // Align text to the left
      row.height = 20; // Set a custom height for the header row
    });

    // Set the column widths based on the header content
    headers.forEach((header, index) => {
      worksheet.getColumn(index + 1).width = header.length + 5; // Adjust the width to fit the header content
    });

    // Setting column headers
    const columnHeaderRow = worksheet.addRow([
      "Subcategory",
      "Current",
      "Prior Month",
      "Diff"
    ]);
    columnHeaderRow.eachCell((cell, colNumber) => {
      if (colNumber > 1) {
        cell.font = { bold: true, color: { argb: "FF008000" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD3D3D3" },
        };
      }
    });

    // Freeze the column header on row 5
    worksheet.views = [
      {
        state: "frozen",
        ySplit: 6, // 5 rows to be frozen
      },
    ];

    // Adding data rows
    data.forEach((row) => {
      let { Subcategory, C, P, Diff, ExcelFormattingCode } = row;

      // If the Subcategory has an underscore, remove it and format the row accordingly
      if (Subcategory && Subcategory.includes("_")) {
        Subcategory = Subcategory.replace(/_/g, "");

        // Create the new row
        const newRow = worksheet.addRow([
          Subcategory,
          Math.abs(C),
          Math.abs(P),
          Math.abs(Diff),
        ]);

        // Apply currency format to each cell individually
        newRow.eachCell((cell, colNumber) => {
          if (colNumber > 1) {
            cell.numFmt = "$#,##0.00"; // Apply the currency format here

            // If value is negative, color font red
            if (cell.value < 0) {
              cell.font = { color: { argb: "FFFF0000" } };
            }
          }
          // Highlight cells
          if (
            ExcelFormattingCode === "Line" ||
            ExcelFormattingCode === "Double Line"
          ) {
            cell.font = { bold: true };
            cell.border = {
              bottom: { style: "thick", color: { argb: "000000" } },
              top: { style: "thick", color: { argb: "000000" } },
            }; // Made row lines bolder for total rows
          }
        });
      } else {
        const newRow = worksheet.addRow([
          Subcategory,
          Math.abs(C),
          Math.abs(P),
          Math.abs(Diff),
        ]);

        // Format cells to currency
        newRow.eachCell((cell, colNumber) => {
          if (colNumber > 1) {
            cell.numFmt = "$#,##0.00"; // Apply the currency format here

            // If value is negative, color font red
            if (cell.value < 0) {
              cell.font = { color: { argb: "FFFF0000" } };
            }
          }
        });
      }
    });

    // Adjusting column widths manually
    worksheet.getColumn(1).width = 40; // Adjust the width as per your requirement
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;

    // Custom Styling
    const row5 = worksheet.getRow(6);
    row5.getCell(1).fill = columnHeaderRow.getCell(2).fill;
    row5.getCell(1).font = columnHeaderRow.getCell(2).font;

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Balance_Sheet_Change.xlsx");
  } catch (error) {
    console.error("Error fetching and exporting data:", error);
  }
};
