import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const fetchAndExportIncomeStatementData = async (
  reportMonth,
  reportYear,
  gpNum,
  community
) => {

  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/uspincomestatement`);
    const data = await response.json();

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Income Statement Change");

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      "en-us",
      { month: "long" }
    );

    const headers = [
      ` ${community}`,
      "Income Statement",
      `For The Month Ending ${monthName}, ${reportYear}`,
    ];

    headers.forEach((header, index) => {
      const row = worksheet.addRow([]);
      row.height = 25;
      const cell = row.getCell(2);
      cell.value = header;
      cell.font = {
        size: index === 0 ? 16 : 14,
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
      cell.alignment = { horizontal: "center" };

      if (index <= 2) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "013220" },
        };
      }

      worksheet.mergeCells(index + 1, 2, index + 1, 6);
    });

    // Add column headers
    const columnHeaderRow = worksheet.addRow([
      "Account",
      "Account Description",
      "Current Month",
      "Previous Month",
      "Difference",
    ]);

    columnHeaderRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

  // Add data rows
data.forEach((rowData) => {
  // Remove the prefix '___' if it exists
  if (rowData.AccountDescription && rowData.AccountDescription.startsWith('___')) {
    rowData.AccountDescription = rowData.AccountDescription.replace('___', '');
  }

  const row = worksheet.addRow([
    rowData.Account,
    rowData.AccountDescription,
    rowData.CurrentMonth,
    rowData.PreviousMonth,
    rowData.Diff,
  ]);

  // Apply currency formatting to numeric cells
  row.eachCell((cell, cellNumber) => {
    if (typeof cell.value === 'number') {
      // Adjust the format here
      cell.numFmt = cell.value < 0 ? '($#,##0.00_);($#,##0.00)' : '$#,##0.00';
    }
  });

  // Check if the row's AccountDescription contains 'Total'
  if (rowData.AccountDescription && rowData.AccountDescription.includes('Total')) {
    // Set the entire row font to bold
    row.font = { bold: true };

    // Adjust cell border to be bold
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thick' },
        left: { style: 'thick' },
        bottom: { style: 'thick' },
        right: { style: 'thick' }
      };
    });
  }
});


    // Adjust column widths
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;

    // Freeze or pin the first 4 rows (header rows)
    worksheet.views = [{ state: "frozen", ySplit: 4 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Income_Statement.xlsx");
  } catch (error) {
    console.error("Error fetching and exporting data:", error);
  }
};
