import React, { useState, useEffect } from 'react';
import { useSearchParams} from 'react-router-dom';
import './JobPostingPage.css'; // Assuming your CSS is saved in a file named JobPostingPage.css

const JobPostingPage = ({ allJobsData }) => {
  const [job, setJob] = useState(null);
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
 // const navigate = useNavigate();

  useEffect(() => {
    const selectedJob = allJobsData.find(job => job.indexCol.toString() === jobId);
    setJob(selectedJob);
  }, [jobId, allJobsData]);

  /*const handleBackClick = () => {
    navigate(-1, { state: { jobIndexCol: job?.indexCol } });
  };*/

  const handleShareClick = () => {
    const jobUrl = `${window.location.origin}/tala.org/job-posting?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);
    alert('Job link copied to clipboard!');
  };

  if (!job) {
    return <div className="loading">Loading job details...</div>;
  }

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div className="job-posting-page">
      <div className="job-posting-buttons">
        {/* Only show the back button on mobile devices */}
       {/* <button className="back-button" onClick={handleBackClick}>Back</button>*/}
        <button onClick={() => window.open(job.JobPostURL, "_self")}>Apply Now</button>
        <button onClick={handleShareClick}>Share Job</button>
      </div>
      <h1>{job.JobTitle}</h1>
      <h2 style={{ color: '#d60000' }}>{job.CompanyName}</h2>
      <p style={{ color: '#030063' }}><strong>Location:</strong> {`${job.StreetAddress}, ${job.City}, ${job.State}, ${job.ZipCode}`}</p>
      <p style={{ color: '#030063' }}><strong>Employment Type:</strong> {job.EmploymentType}</p>
      <p style={{ color: '#030063' }}><strong>Related MOS:</strong> {job.MOSCode}</p>
      <p style={{ color: '#030063' }}><strong>Job Category:</strong> {job.JobCategory}</p>
      <div dangerouslySetInnerHTML={createMarkup(job.JobDescription)} />
      <Footer />
    </div>
  );
};

const Footer = () => (
  <footer className="job-posting-footer">
    © 2024 Tala. All rights reserved.
  </footer>
);

export default JobPostingPage;
