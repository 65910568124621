import React, { useState, useEffect } from "react";
import "../App.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import Select from "react-select";
import "../FrmGlDetail.css";
import { glActivityData } from "./glactivityreport";
import { fetchUserEmail} from "./userAccess";
import {logReportActivity} from "./reportActivityLog";

const FrmGlActivity = () => {
  const [value2, setValue2] = useState(null);
  const [value, setValue] = useState(null);
  const [showCalendar1, setShowCalendar1] = useState(false);
  const [showCalendar2, setShowCalendar2] = useState(false);
  const [owners, setOwners] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const [includePriorOwners, setIncludePriorOwners] = useState(false);
  const [originalSelectedCommunities, setOriginalSelectedCommunities] =
    useState([]);
  //const [selectedOutputOption, setSelectedOutputOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [userEmail, setUserEmail] = useState("");

  // Fetch user email on mount
  useEffect(() => {
   const loadUserEmail = async () => {
     const email = await fetchUserEmail();
     setUserEmail(email);
   };

   loadUserEmail();
 }, []);

  useEffect(() => {
    const getOwners = async () => {
      try {
        const response = await axios.get(
          "/api/vActiveCommunities",
          {
            withCredentials: true,
          }
        );

        const data = response.data;

        // Extract unique owners from the data
        const uniqueOwners = [
          ...new Set(data.map((community) => community.Owner)),
        ];

        const options = uniqueOwners.map((owner) => {
          return {
            value: owner,
            label: owner,
          };
        });

        setOwners(options);
      } catch (error) {
        console.log(error);
      }
    };

    getOwners();

    const getCommunities = async () => {
      try {
        const response = await axios.get(
          "/api/vActiveCommunities",
          {
            withCredentials: true,
          }
        );
        const data = response.data;

        const options = data.map((communities) => {
          return {
            value: communities.CommunityNum,
            label: `${communities.CommunityNum} - ${communities.CommunityShortName}`,
            owner: communities.Owner, // Add the owner property here
          };
        });

        // add Select All option to communities
        options.unshift({ value: "all", label: "Select All Communities" });

        setCommunities(options);
      } catch (error) {
        console.log(error);
      }
    };

    getCommunities();
  }, []);
  const validateFields = () => {
    let errorFields = [];
    const currentDate = new Date(); // Current date without time
    currentDate.setHours(0, 0, 0, 0);
  
    //if (!selectedOutputOption) errorFields.push("Output Option");
    if (!value) errorFields.push("Beginning Date");
    if (!value2) errorFields.push("Ending Date");
  
    if (value && value2 && value2 < value) {
      alert("Ending period cannot be earlier than beginning period.");
      return false;
    }
  
    if (value2 > currentDate) {
      alert("Ending period cannot be a future date.");
      return false;
    }
  
    if (selectedOwner === null) errorFields.push("Owner");
    if (selectedCommunities.length === 0) errorFields.push("Communities");
  
    if (errorFields.length > 0) {
      alert(`The following fields can't be blank: ${errorFields.join(", ")}`);
      return false;
    }
  
    return true;
  };
  
  
  const handleOwnerChange = async (selectedOption) => {
    setSelectedOwner(selectedOption.value);

    // Filter communities based on selected owner and includePriorOwners state
    const filteredOptions = communities.filter((community) => {
      const isSelectedOwner = community.owner === selectedOption.value;
      const isSelectAll = community.value === "all";
      const hasPriorOwner = community.label
        .toLowerCase()
        .includes("prior owner");

      if (isSelectedOwner || isSelectAll) {
        return includePriorOwners ? true : !hasPriorOwner;
      }

      return false;
    });

    setFilteredCommunities(filteredOptions);
  };
  const handleIncludePriorOwners = (event) => {
    const isChecked = event.target.checked;

    setIncludePriorOwners(isChecked);

    // Filter the available communities based on the selected owner and includePriorOwners state
    const filteredOptions = communities.filter((community) => {
      const isSelectedOwner =
        !selectedOwner || community.owner === selectedOwner;
      const isSelectAll = community.value === "all";
      const hasPriorOwner = community.label
        .toLowerCase()
        .includes("prior owner");

      if (isSelectedOwner || isSelectAll) {
        return isChecked || !hasPriorOwner;
      }

      return false;
    });

    setFilteredCommunities(filteredOptions);

    // Reset the selected communities to the original selection if the checkbox is checked again
    if (isChecked) {
      setSelectedCommunities(originalSelectedCommunities);
    } else {
      setSelectedCommunities((prevSelectedCommunities) => {
        return prevSelectedCommunities.filter(
          (value) =>
            !filteredCommunities
              .find((option) => option.value === value)
              ?.label.toLowerCase()
              .includes("prior owner")
        );
      });
    }
  };

  // ...

  const handleCommunityChange = (selectedOptions) => {
    if (selectedOptions) {
      const selectedValues = selectedOptions.map((option) => option.value);
      if (selectedValues.includes("all")) {
        setSelectedCommunities(
          communities
            .filter((option) => {
              const isSelectedOwner =
                selectedOwner === null || option.owner === selectedOwner;
              const isSelectAll = option.value === "all";
              const hasPriorOwner = option.label
                .toLowerCase()
                .includes("prior owner");

              return (
                !isSelectAll &&
                isSelectedOwner &&
                (includePriorOwners ? true : !hasPriorOwner)
              );
            })
            .map((option) => option.value)
        );
      } else {
        setSelectedCommunities(
          selectedValues.filter((value) => value !== "all")
        );
      }

      // Store the original selected communities
      setOriginalSelectedCommunities(
        selectedValues.filter((value) => value !== "all")
      );

      // Filter the available communities based on the selected owner and includePriorOwners state
      const filteredOptions = communities.filter((community) => {
        const isSelectedOwner = community.owner === selectedOwner;
        const isSelectAll = community.value === "all";
        const hasPriorOwner = community.label
          .toLowerCase()
          .includes("prior owner");

        if (isSelectedOwner || isSelectAll) {
          return includePriorOwners ? true : !hasPriorOwner;
        }

        return false;
      });

      setFilteredCommunities(filteredOptions);
    } else {
      setSelectedCommunities([]);
      setFilteredCommunities(communities);
    }
  };
  /*const handleOutputOptionChange = (event) => {
    setSelectedOutputOption(event.target.value);
  };*/
  const handleSecondPostingDateChange = (newValue) => {
    setValue2(newValue);
    setShowCalendar2(false);
  };
  const handleProcessClick = () => {
    if (!validateFields()) return; // Validate before processing
  
    setIsLoading(true); // Start loading
    setProgressPercentage(0); // Reset to 0 when the process starts
  
    let simulatedProgress = 0;
  
    const progressSimulation = setInterval(() => {
      simulatedProgress += 1;
      if (simulatedProgress <= 95) {
        setProgressPercentage(simulatedProgress);
      } else {
        clearInterval(progressSimulation);
      }
    }, 100);
  
    axios.post(
      "/api/glactivitypostreq",
      {
        // Your post data
        BegDate: value,
        EndDate: value2,
        owner: selectedOwner,
        GPNum: selectedCommunities,
      },
      {
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted >= 95) {
            clearInterval(progressSimulation);
          }
          setProgressPercentage(percentCompleted);
        },
      }
    )
    .then((response) => {
      console.log(response.data);
      glActivityData(communities, value, value2, originalSelectedCommunities);
  
     // Log the report activity after successful report generation
    if (userEmail) {
      // Map the GPNums to their full community names
      const communityNames = originalSelectedCommunities.map(gpNum => {
        const community = communities.find(c => c.value === gpNum);
        return community ? `${community.value} - ${community.label}` : gpNum;
      }).join(", ");

      logReportActivity(userEmail, { label: "GL Detailed Activity Report" }, communityNames);
    }
  })
    .catch((error) => {
      clearInterval(progressSimulation);
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        setIsLoading(false); // End loading after a delay
      }, 10000);
    });
  };
  

  return (
    <div className="form-container">
      <h5 className="form-title">GL Detailed Activity Report</h5>

      {isLoading && (
        <div className="loading-bar-container">
          <div
            className="loading-bar"
            style={{ width: `${progressPercentage}%` }}
          ></div>
          <span>{progressPercentage}%</span>
        </div>
      )}

     {/*   <div className="form-element">
        <label htmlFor="sort">Output Option</label>
        <select
          id="sort"
          className="select"
          defaultValue=""
          onChange={handleOutputOptionChange}
        >
          <option value="" disabled>
            Select Output Option
          </option>
          <option value="excel_raw">Export To Excel (RAW)</option>
          <option value="monthly_intranet_pretty">
            Monthly Export To Intranet (Pretty)
          </option>
          <option value="excel_pretty">Export To Excel (Pretty)</option>
        </select>
      </div> */}

      <div className="form-element">
        <label htmlFor="first-posting-date">Beginning Date</label>

        <input
          type="text"
          id="first-posting-date"
          className="input"
          value={value ? value.toLocaleDateString() : "Select Beginning Date"}
          onClick={() => setShowCalendar1(!showCalendar1)}
          readOnly
        />

        {showCalendar1 && (
          <Calendar
            onChange={(newValue) => {
              setValue(newValue);
              setShowCalendar1(false);
            }}
            value={value}
          />
        )}
      </div>

      <div className="form-element">
        <label htmlFor="last-posting-date">Ending Date</label>
        <input
          type="text"
          id="last-posting-date"
          className="input"
          value={value2 ? value2.toLocaleDateString() : "Select Ending Date"}
          onClick={() => setShowCalendar2(!showCalendar2)}
          readOnly
        />

        {showCalendar2 && (
          <Calendar onChange={handleSecondPostingDateChange} value={value2} />
        )}
      </div>

      {/* form element for owners*/}
      <div className="form-element">
        <label htmlFor="select-owner">Owner</label>
        <select
          id="select-owner"
          className="select"
          onChange={(e) => handleOwnerChange({ value: e.target.value })}
        >
          <option value="" disabled selected>
            Select Owner
          </option>
          {owners.map((owner) => (
            <option value={owner.value}>{owner.label}</option>
          ))}
        </select>
      </div>

      {/* form element for communities*/}

      <div className="form-element communities-container">
        <div className="community-select">
          <label htmlFor="select-community">Select Community(ies)</label>

          {/* Add the "Include Prior Owners" checkbox and label */}
          <div style={{ marginLeft: "4px", marginTop: "10px" }}>
            <input
              type="checkbox"
              id="include-prior-owners"
              checked={includePriorOwners}
              onChange={handleIncludePriorOwners}
            />
            <label htmlFor="include-prior-owners" style={{ marginLeft: "4px" }}>
              Include Prior Owners
            </label>
          </div>

          <Select
            id="community"
            options={filteredCommunities}
            isMulti
            onChange={handleCommunityChange}
            closeMenuOnSelect={false}
            placeholder="Select Community(ies)"
            value={selectedCommunities.map((community) =>
              filteredCommunities.find((option) => option.value === community)
            )}
          />
        </div>
      </div>

      {/* ... form elements ... */}
      <div className="form-element button-container">
        <button className="half-width-button" onClick={handleProcessClick}>
          Process
        </button>

       {/* <button className="half-width-button">Return to Menu</button> */}
      </div>
    </div>
  );
};

export default FrmGlActivity;
