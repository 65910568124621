import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import "./TalaLandingPage2.css";
import talaBanner from "./talaBanner.png";
import SearchFilterContainer from "./searchContainer";
import { useLocation } from "react-router-dom";
import logVisit from "./visitLogger";

const JobSummary = ({ job, onClick, isSelected }) => {
  return (
    <div
      className={`job-summary ${isSelected ? "selected" : ""}`}
      onClick={() => onClick(job)}
    >
      <h2>{job.JobTitle}</h2>
      <p className="mos-code">
        Related MOS: {job.MOSCode} &nbsp; &nbsp; &nbsp; Employment Type:{" "}
        {job.EmploymentType}
      </p>{" "}
      <p>{`${job.StreetAddress}, ${job.City}, ${job.State}, ${job.ZipCode}`}</p>
      <p className="job-category">{job.JobCategory}</p>
      <h3>{job.CompanyName}</h3>
    </div>
  );
};

const JobDetail = ({ job, onShare }) => {
  const isMobile = window.innerWidth <= 768;

  const getWordLimit = () => (isMobile ? 100 : 130);

  const truncateDescription = (htmlContent) => {
    const wordLimit = getWordLimit();
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    let textContent = doc.body.textContent || "";
    let words = textContent.trim().split(/\s+/);

    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    } else {
      return htmlContent;
    }
  };

  const renderDescription = () => {
    const fullDescription = truncateDescription(job.JobDescription);
    const showReadMore =
      !isMobile && job.JobDescription.split(" ").length > getWordLimit();
    const jobPageUrl = `${window.location.origin}/tala.org/job-posting?jobId=${job.indexCol}`;

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
        {showReadMore && (
          <a
            href={jobPageUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="read-more"
          >
            Read more
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="job-detail">
      <h2>
        {job.JobTitle} - {job.CompanyName}
      </h2>
      <p className="mos-code-detail">Related MOS: {job.MOSCode}</p>
      <p>{`${job.StreetAddress}, ${job.City}, ${job.State}, ${job.ZipCode}`}</p>
      {renderDescription()}
      <div className="job-detail-buttons">
        <a
          className="apply-button"
          href={job.JobPostURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Apply Now
        </a>
        <button className="share-button" onClick={() => onShare(job)}>
          Share
        </button>
      </div>
    </div>
  );
};

const isRunningInIframe = () => window.location !== window.parent.location;

const JobDetailModal = ({ job, isOpen, onClose, onShare }) => {
  const [originalOverflow, setOriginalOverflow] = useState("");

  useEffect(() => {
    if (isRunningInIframe()) {
      const handleParentMessage = (event) => {
        if (event.origin === "https://tala.org") {
        } else {
          console.log(
            "Received message from unauthorized origin:",
            event.origin
          );
        }
      };
      window.addEventListener("message", handleParentMessage);

      return () => window.removeEventListener("message", handleParentMessage);
    }
  }, []);

  useEffect(() => {
    if (isOpen && isRunningInIframe()) {
      const message = { type: "ModalOpen", details: "AdjustScroll" };
      window.parent.postMessage(message, "https://tala.org");
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setOriginalOverflow(document.body.style.overflow);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalOverflow;
    }
  }, [isOpen, originalOverflow]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [originalOverflow]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="red"
            width="24px"
            height="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
          </svg>
        </button>
        {job && <JobDetail job={job} onShare={onShare} />}
      </div>
    </Modal>
  );
};

const HeaderWithImage = () => (
  <div className="header-with-image">
    <img src={talaBanner} alt="TALA Job Search" />
    <div className="header-text">
      <p>
        Senior living communities offer a wide range of job opportunities that
        fit a variety of skills and backgrounds such as sales, hospitality
        services, culinary, recreational programs, healthcare support,
        leadership and much more!
      </p>
      <p style={{ fontWeight: "bold", fontSize: "larger" }}>
        Search TALA Members' open positions below.
      </p>
      <p>
        Not sure what position fit your skills best, learn about the various
        roles senior living communities offer at TALA’s{" "}
        <a
          href="https://tala.org/careers-in-al/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Careers In AL webpage
        </a>
        .
      </p>
    </div>
  </div>
);

const TalaLandingPage2 = () => {
  const [allJobListingsData, setAllJobListingsData] = useState([]);
  const [jobListingsData, setJobListingsData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [stateFilter, setStateFilter] = useState("TX");
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState("any");
  const [jobCategoryFilter, setJobCategoryFilter] = useState("any");
  const [talaMemberFilter, setTalaMemberFilter] = useState("any");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mosCodeFilter, setMosCodeFilter] = useState("");
  const [totalJobsCount, setTotalJobsCount] = useState(0);
  const [texasJobsCount, setTexasJobsCount] = useState(0);
  const location = useLocation();
  const [matchingJobsCount, setMatchingJobsCount] = useState(0);

  const jobsPerPage = 20;

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API;
    axios
      .get(`${apiUrl}/taladata`)
      .then((response) => {
        const data = response.data;
        setAllJobListingsData(data);
        setTotalJobsCount(data.length);
        const texasJobs = data.filter((job) => job.State === "TX");
        setTexasJobsCount(texasJobs.length);
        setJobListingsData(texasJobs);
        setMatchingJobsCount(texasJobs.length);
        setTotalPages(Math.ceil(texasJobs.length / jobsPerPage));
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  useEffect(() => {
    setMatchingJobsCount(jobListingsData.length);
  }, [jobListingsData]);

  const handleStateFilterChange = (event) => {
    const newStateFilter = event.target.value;
    setStateFilter(newStateFilter);

    const newFilteredJobs = filterJobs(
      newStateFilter,
      employmentTypeFilter,
      jobCategoryFilter,
      talaMemberFilter,
      searchTerm
    );
    setJobListingsData(newFilteredJobs);
    setMatchingJobsCount(newFilteredJobs.length);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
    let endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  useEffect(() => {
    const jobIndexCol = location.state?.jobIndexCol;

    if (jobIndexCol) {
      const element = document.getElementById(`job-${jobIndexCol}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  const shareJob = async (job) => {
    const jobLink = `${window.location.origin}/tala.org/job-posting?jobId=${job.indexCol}`;

    try {
      await navigator.clipboard.writeText(jobLink);
      alert(`Copied the link: ${jobLink}`);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
    }
  }, []);

  const selectJob = (job) => {
    logVisit(job.indexCol); // Log the visit with the job ID
    const jobPageUrl = `${window.location.origin}/tala.org/job-posting?jobId=${job.indexCol}`;
    window.open(jobPageUrl, "_blank");
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const matchesSearchTerm = useCallback(
    (job) => {
      const jobTitle = job.JobTitle || "";
      const city = job.City || "";
      const state = job.State || "";
      const zipCode = job.ZipCode ? job.ZipCode.toString() : "";
      const companyName = job.CompanyName || "";

      return (
        jobTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        state.toLowerCase().includes(searchTerm.toLowerCase()) ||
        zipCode.includes(searchTerm) ||
        companyName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    [searchTerm]
  );

  const matchesTalaMember = useCallback(
    (job) => {
      const lowerCaseCompanyName = job.CompanyName.toLowerCase();
      return (
        talaMemberFilter === "any" ||
        (talaMemberFilter === "atria" &&
          lowerCaseCompanyName.includes("atria")) ||
        (talaMemberFilter === "avanti" &&
          lowerCaseCompanyName.includes("avanti")) ||
        (talaMemberFilter === "aspenwood" &&
          lowerCaseCompanyName.includes("aspenwood")) ||
        (talaMemberFilter === "belmont" &&
          lowerCaseCompanyName.includes("belmont")) ||
        (talaMemberFilter === "brookdale" &&
          lowerCaseCompanyName.includes("brookdale")) ||
        (talaMemberFilter === "discovery" &&
          lowerCaseCompanyName.includes("discovery")) ||
        (talaMemberFilter === "fivestar" &&
          lowerCaseCompanyName.includes("fivestar")) ||
        (talaMemberFilter === "frontier" &&
          lowerCaseCompanyName.includes("frontier")) ||
        (talaMemberFilter === "legend" &&
          lowerCaseCompanyName.includes("legend")) ||
        (talaMemberFilter === "lifewell" &&
          lowerCaseCompanyName.includes("lifewell")) ||
        (talaMemberFilter === "moody" &&
          lowerCaseCompanyName.includes("moody")) ||
        (talaMemberFilter === "sinceri" &&
          lowerCaseCompanyName.includes("sinceri")) ||
        (talaMemberFilter === "sonida" &&
          lowerCaseCompanyName.includes("sonida")) ||
        (talaMemberFilter === "sunrise" &&
          lowerCaseCompanyName.includes("sunrise")) ||
        (talaMemberFilter === "silverado" &&
          lowerCaseCompanyName.includes("silverado")) ||
        (talaMemberFilter === "pegasus" &&
          lowerCaseCompanyName.includes("pegasus")) ||
        (talaMemberFilter === "senior resource group" &&
          lowerCaseCompanyName.includes("senior resource group")) ||
        (talaMemberFilter === "integral senior living" &&
          lowerCaseCompanyName.includes("integral senior living")) ||
        (talaMemberFilter === "sagora" &&
          lowerCaseCompanyName.includes("sagora")) ||
        (talaMemberFilter === "mustang" &&
          lowerCaseCompanyName.includes("mustang")) ||
        (talaMemberFilter === "civitas" &&
          lowerCaseCompanyName.includes("civitas")) ||
        (talaMemberFilter === "sodalis" &&
          lowerCaseCompanyName.includes("sodalis")) ||
        (talaMemberFilter === "silverpoint" &&
          lowerCaseCompanyName.includes("silverpoint")) ||
        (talaMemberFilter === "iris" &&
          lowerCaseCompanyName.includes("iris")) ||
        (talaMemberFilter === "country" &&
          lowerCaseCompanyName.includes("country")) ||
        (talaMemberFilter === "morada" &&
          lowerCaseCompanyName.includes("morada")) ||
        (talaMemberFilter === "stoney brook" &&
          lowerCaseCompanyName.includes("stoney brook")) ||
        (talaMemberFilter === "cardinal bay" &&
          lowerCaseCompanyName.includes("cardinal bay")) ||
        (talaMemberFilter === "teresa's house" &&
          lowerCaseCompanyName.includes("teresa's house"))
      );
    },
    [talaMemberFilter]
  );

  const deduplicateJobs = useCallback((jobs) => {
    const uniqueJobs = new Map();
    jobs.forEach((job) => {
      const jobKey = `${job.JobTitle}-${job.CompanyName}-${job.ZipCode}`;
      if (!uniqueJobs.has(jobKey)) {
        uniqueJobs.set(jobKey, job);
      }
    });
    return Array.from(uniqueJobs.values());
  }, []);

  const filterJobs = useCallback(() => {
    let filteredJobs = deduplicateJobs([...allJobListingsData]);

    if (stateFilter !== "any") {
      filteredJobs = filteredJobs.filter((job) => job.State === stateFilter);
    }

    if (employmentTypeFilter !== "any") {
      filteredJobs = filteredJobs.filter((job) => {
        const employmentType = job.EmploymentType || "";
        return employmentType.toLowerCase().includes(employmentTypeFilter);
      });
    }

    if (jobCategoryFilter !== "any") {
      filteredJobs = filteredJobs.filter((job) => {
        const jobCategory = job.JobCategory || "";
        return jobCategory
          .toLowerCase()
          .includes(jobCategoryFilter.toLowerCase());
      });
    }

    if (mosCodeFilter.trim() !== "") {
      const searchMOS = mosCodeFilter.trim().toLowerCase();
      filteredJobs = filteredJobs.filter((job) => {
        const mosCode = job.MOSCode || "";
        return mosCode
          .split(", ")
          .map((code) => code.trim().toLowerCase())
          .some((code) => code.startsWith(searchMOS));
      });
    }

    if (talaMemberFilter !== "any") {
      filteredJobs = filteredJobs
        .filter(matchesTalaMember)
        .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
    }

    if (searchTerm.trim() !== "") {
      filteredJobs = filteredJobs.filter(matchesSearchTerm);
    }

    return filteredJobs;
  }, [
    allJobListingsData,
    stateFilter,
    employmentTypeFilter,
    jobCategoryFilter,
    talaMemberFilter,
    matchesTalaMember,
    matchesSearchTerm,
    searchTerm,
    deduplicateJobs,
    mosCodeFilter,
  ]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterJobs();
  };

  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const filteredJobs = allJobListingsData.filter((job) => {
      const employmentType = job.EmploymentType || "";
      return (
        (stateFilter === "any" || job.State === stateFilter) &&
        (employmentTypeFilter === "any" ||
          employmentType.toLowerCase().includes(employmentTypeFilter)) &&
        (jobCategoryFilter === "any" ||
          (job.JobCategory &&
            job.JobCategory.toLowerCase().includes(
              jobCategoryFilter.toLowerCase()
            ))) &&
        matchesTalaMember(job) &&
        matchesSearchTerm(job)
      );
    });

    setJobListingsData(filteredJobs);
    setTotalPages(Math.ceil(filteredJobs.length / jobsPerPage));
    setCurrentPage(1);
  }, [
    stateFilter,
    employmentTypeFilter,
    jobCategoryFilter,
    talaMemberFilter,
    searchTerm,
    matchesTalaMember,
    matchesSearchTerm,
    allJobListingsData,
  ]);

  const getCurrentJobs = () => {
    const filteredJobs = filterJobs(jobListingsData);
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    return filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  };

  const currentJobs = getCurrentJobs();

  return (
    <div className="tala-landing-page-2">
      <HeaderWithImage />
      <SearchFilterContainer
        handleSearch={(e) => handleSearchChange(e)}
        searchTerm={searchTerm}
        setMosCodeFilter={(value) => setMosCodeFilter(value)}
        handleStateFilterChange={(e) => handleStateFilterChange(e)}
        stateFilter={stateFilter}
        handleEmploymentTypeFilterChange={(e) =>
          setEmploymentTypeFilter(e.target.value)
        }
        employmentTypeFilter={employmentTypeFilter}
        handleJobCategoryFilterChange={(e) =>
          setJobCategoryFilter(e.target.value)
        }
        jobCategoryFilter={jobCategoryFilter}
        handleTalaMemberFilterChange={(e) =>
          setTalaMemberFilter(e.target.value)
        }
        talaMemberFilter={talaMemberFilter}
        totalJobsCount={totalJobsCount}
        texasJobsCount={texasJobsCount}
        matchingJobsCount={matchingJobsCount}
      />
      <div className="page-navigation">
        <span>Showing page </span>
        <input
          type="number"
          value={currentPage}
          onChange={handlePageChange}
          min={1}
          max={totalPages}
          className="page-input"
        />
        <span> of {totalPages}</span>
      </div>
      <div className="content">
        <div className="job-summaries-full-width">
          {currentJobs.map((job) => (
            <JobSummary
              key={job.indexCol}
              id={`job-${job.indexCol}`}
              job={job}
              onClick={selectJob}
              isSelected={selectedJob?.indexCol === job.indexCol}
            />
          ))}
        </div>
        <JobDetailModal
          job={selectedJob}
          isOpen={isModalOpen}
          onClose={handleClose}
          onShare={shareJob}
        />
      </div>
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={() => setCurrentPage(1)}>First</button>
        )}
        {currentPage > 1 && (
          <button onClick={() => setCurrentPage(currentPage - 1)}>Prev</button>
        )}
        {renderPageNumbers()}
        {currentPage < totalPages && (
          <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
        )}
        {currentPage < totalPages && (
          <button onClick={() => setCurrentPage(totalPages)}>Last</button>
        )}
      </div>
    </div>
  );
};

export default TalaLandingPage2;
