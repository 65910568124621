export const config = {
    tenants: {
        frontier: {
            clientId: "9e5035da-8289-41cb-90b1-56a25e10ae3d",
            authority: "https://login.microsoftonline.com/e4238286-0361-453e-b882-8c957915c4fc",
            redirectUri: "https://safeharbor.pro/",
        },
        waxman: {
            clientId: "3014f96d-e5d9-4738-82ed-d017dc3c1802",
            authority: "https://login.microsoftonline.com/1dcf02b8-fdce-4342-81ee-8e2a5f7fa5bd",
            redirectUri: "https://safeharbor.pro/",
        }
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};
