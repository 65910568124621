import React, { useState, useEffect } from "react";
import "../App.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { saveAs } from "file-saver";
import Select from "react-select";
import "../FrmGlDetail.css";
import ExcelJS from "exceljs";
import { fetchUserEmail} from "./userAccess";
import {logReportActivity} from "./reportActivityLog";


const FrmGlDetail = () => {
  const [value2, setValue2] = useState(null);
  const [value, setValue] = useState(null);
  const [showCalendar1, setShowCalendar1] = useState(false);
  const [showCalendar2, setShowCalendar2] = useState(false);
  const [glAccounts, setGlAccounts] = useState([]);
  const [owners, setOwners] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [firstGlAccount, setFirstGlAccount] = useState(null);
  const [lastGlAccount, setLastGlAccount] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const [includePriorOwners, setIncludePriorOwners] = useState(false);
  const [originalSelectedCommunities, setOriginalSelectedCommunities] =
    useState([]);
  //const [openInWeb, setOpenInWeb] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [userEmail, setUserEmail] = useState("");

    // Fetch user email on mount
    useEffect(() => {
      const loadUserEmail = async () => {
        const email = await fetchUserEmail();
        setUserEmail(email);
      };
   
      loadUserEmail();
    }, []);

  useEffect(() => {
    const getGlAccounts = async () => {
      try {
        const response = await axios.get("/api/vAllAccounts", {
          withCredentials: true,
        });
        const data = response.data;

        const options = data.map((account) => {
          return {
            value: account.Account,
            label: `${account.Account} - ${account.Description}`,
          };
        });

        setGlAccounts(options);
      } catch (error) {
        console.log(error);
      }
    };

    getGlAccounts();

    const getOwners = async () => {
      try {
        const response = await axios.get(
          "/api/vActiveCommunities",
          {
            withCredentials: true,
          }
        );

        const data = response.data;

        // Extract unique owners from the data
        const uniqueOwners = [
          ...new Set(data.map((community) => community.Owner)),
        ];

        const options = uniqueOwners.map((owner) => {
          return {
            value: owner,
            label: owner,
          };
        });

        setOwners(options);
      } catch (error) {
        console.log(error);
      }
    };

    getOwners();

    const getCommunities = async () => {
      try {
        const response = await axios.get(
          "/api/vActiveCommunities",
          {
            withCredentials: true,
          }
        );
        const data = response.data;

        const options = data.map((communities) => {
          return {
            value: communities.CommunityNum,
            label: `${communities.CommunityNum} - ${communities.CommunityShortName}`,
            owner: communities.Owner, // Add the owner property here
          };
        });

        // add Select All option to communities
        options.unshift({ value: "all", label: "Select All Communities" });

        setCommunities(options);
      } catch (error) {
        console.log(error);
      }
    };

    getCommunities();
  }, []);

  const downloadReport = async () => {
    try {
      const response = await axios.get(
        "/api/spCrossCompanyGLandAPTransctionDetail",
        { withCredentials: true }
      );
      const data = response.data;

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(
        "Cross Company GL Account Analysis Report (Enhanced)"
      );

      // Apply formatting and styling
      const header1 = "Cross Company GL Account Analysis Report (Enhanced)";
      const header2 = `GL Acct#: ${firstGlAccount} through ${lastGlAccount}`;
      const header3 = `Posted between ${value.toLocaleDateString()} - ${value2.toLocaleDateString()}`;
      const header4 = "Data pulled from JE Detail & AP Invoice Detail";
      const mergedHeaders = [header1, header2, header3, header4];

      for (let R = 0; R < 4; ++R) {
        const row = worksheet.getRow(R + 1);
        row.height = 25;
        row.getCell(1).value = mergedHeaders[R];
        row.getCell(1).font = {
          size: R === 0 ? 16 : 14,
          bold: true,
          color: { argb: "FFFFFFFF" },
        };
        row.getCell(1).alignment = { horizontal: "center" };
        row.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "0077B6" },
        };
        worksheet.mergeCells(R + 1, 1, R + 1, 8);
      }
      // Add the column headers
      if (data.length > 0) {
        const headers = Object.keys(data[0]);
        headers.forEach((header, idx) => {
          worksheet.getRow(7).getCell(idx + 1).value = header;
          worksheet.getRow(7).getCell(idx + 1).font = {
            bold: true,
            color: { argb: "0077B6" },
          };
        });

        // Bind the pulled data starting from row 8
        const j = 9; // Set this to the column index for "Amount", which is the 10th column in 0-based index
        data.forEach((rowData, rowIdx) => {
          const row = worksheet.getRow(rowIdx + 8);
          Object.values(rowData).forEach((value, colIdx) => {
            if (colIdx === j && typeof value === "number") {
              // Apply the currency format and handle negative numbers
              row.getCell(colIdx + 1).numFmt = "$#,##0.00";
              // If the value is negative, take its absolute value to remove the minus sign
              row.getCell(colIdx + 1).value =
                value < 0 ? Math.abs(value) : value;
            } else {
              row.getCell(colIdx + 1).value = value;
            }
          });
        });
      } else {
        worksheet.getRow(7).getCell(1).value = "No data returned";
      }

      // Adjust column widths
      worksheet.columns = [
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ];
      // Freeze or pin the first 6 rows (header rows)
      worksheet.views = [{ state: "frozen", ySplit: 7 }];
      if (0 > 1) {
        // Convert the workbook to a base64 string.
        const base64 = await workbook.xlsx.writeBuffer({ base64: true });

        // Create a data URI from the base64 string.
        const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;

        // Open the data URI in a new browser tab.
        window.open(dataURI, "_blank");
      } else {
        const buffer = await workbook.xlsx.writeBuffer({
          onUpdate: (progress) => {},
        });
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        saveAs(
          blob,
          "Cross Company GL Account Analysis Report (Enhanced).xlsx"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOwnerChange = async (selectedOption) => {
    setSelectedOwner(selectedOption.value);

    // Filter communities based on selected owner and includePriorOwners state
    const filteredOptions = communities.filter((community) => {
      const isSelectedOwner = community.owner === selectedOption.value;
      const isSelectAll = community.value === "all";
      const hasPriorOwner = community.label
        .toLowerCase()
        .includes("prior owner");

      if (isSelectedOwner || isSelectAll) {
        return includePriorOwners ? true : !hasPriorOwner;
      }

      return false;
    });

    setFilteredCommunities(filteredOptions);
  };
  const handleIncludePriorOwners = (event) => {
    const isChecked = event.target.checked;

    setIncludePriorOwners(isChecked);

    // Filter the available communities based on the selected owner and includePriorOwners state
    const filteredOptions = communities.filter((community) => {
      const isSelectedOwner =
        !selectedOwner || community.owner === selectedOwner;
      const isSelectAll = community.value === "all";
      const hasPriorOwner = community.label
        .toLowerCase()
        .includes("prior owner");

      if (isSelectedOwner || isSelectAll) {
        return isChecked || !hasPriorOwner;
      }

      return false;
    });

    setFilteredCommunities(filteredOptions);

    // Reset the selected communities to the original selection if the checkbox is checked again
    if (isChecked) {
      setSelectedCommunities(originalSelectedCommunities);
    } else {
      setSelectedCommunities((prevSelectedCommunities) => {
        return prevSelectedCommunities.filter(
          (value) =>
            !filteredCommunities
              .find((option) => option.value === value)
              ?.label.toLowerCase()
              .includes("prior owner")
        );
      });
    }
  };

  const handleFirstGlAccountChange = (selectedOption) => {
    console.log("Selected First GL Account:", selectedOption);
    setFirstGlAccount(selectedOption ? selectedOption.value : null);
  };

  const handleLastGlAccountChange = (selectedOption) => {
    console.log("Selected Last GL Account:", selectedOption);
    setLastGlAccount(selectedOption ? selectedOption.value : null);
  };

  const handleCommunityChange = (selectedOptions) => {
    if (selectedOptions) {
      const selectedValues = selectedOptions.map((option) => option.value);
      if (selectedValues.includes("all")) {
        setSelectedCommunities(
          communities
            .filter((option) => {
              const isSelectedOwner =
                selectedOwner === null || option.owner === selectedOwner;
              const isSelectAll = option.value === "all";
              const hasPriorOwner = option.label
                .toLowerCase()
                .includes("prior owner");

              return (
                !isSelectAll &&
                isSelectedOwner &&
                (includePriorOwners ? true : !hasPriorOwner)
              );
            })
            .map((option) => option.value)
        );
      } else {
        setSelectedCommunities(
          selectedValues.filter((value) => value !== "all")
        );
      }

      // Store the original selected communities
      setOriginalSelectedCommunities(
        selectedValues.filter((value) => value !== "all")
      );

      // Filter the available communities based on the selected owner and includePriorOwners state
      const filteredOptions = communities.filter((community) => {
        const isSelectedOwner = community.owner === selectedOwner;
        const isSelectAll = community.value === "all";
        const hasPriorOwner = community.label
          .toLowerCase()
          .includes("prior owner");

        if (isSelectedOwner || isSelectAll) {
          return includePriorOwners ? true : !hasPriorOwner;
        }

        return false;
      });

      setFilteredCommunities(filteredOptions);
    } else {
      setSelectedCommunities([]);
      setFilteredCommunities(communities);
    }
  };

  const handleSecondPostingDateChange = (newValue) => {
    setValue2(newValue);
    setShowCalendar2(false);
  };
  const handleProcessClick = () => {
    // Validate dates
    if (value2 && value && value2 < value) {
      alert("Last posting date cannot be earlier than first posting date.");
      return;
    }

    // Check if value2 (last posting date) is a future date.
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds and ms to get a clean date comparison
    if (value2 && value2 > today) {
      alert("Last posting date cannot be a future date.");
      return;
    }

    // Validate blank fields
    let missingFields = [];
    if (!firstGlAccount) missingFields.push("First GL Account");
    if (!lastGlAccount) missingFields.push("Last GL Account");
    if (!value) missingFields.push("First Posting Date");
    if (!value2) missingFields.push("Last Posting Date");
    if (!selectedOwner) missingFields.push("Owner");
    if (selectedCommunities.length === 0) missingFields.push("Communities");

    if (missingFields.length) {
      alert(
        `The following fields cannot be blank:\n${missingFields.join("\n")}`
      );
      return;
    }
    setIsLoading(true); // Start loading
    setProgressPercentage(0); // Reset to 0 when the process starts

    let simulatedProgress = 0; // Start simulated progress from 0

    const progressSimulation = setInterval(() => {
      simulatedProgress += 1; // Increment by a certain value, adjust to make it slower/faster
      if (simulatedProgress <= 95) {
        // Don't let the simulation go beyond 95%
        setProgressPercentage(simulatedProgress);
      } else {
        clearInterval(progressSimulation);
      }
    }, 100); // Adjust the 100ms interval if the progress goes too fast or slow

    axios
      .post(
        "/api/glpostreq",
        {
          first_gl_account: firstGlAccount,
          last_gl_account: lastGlAccount,
          first_posting_date: value,
          last_posting_date: value2,
          owner: selectedOwner,
          communities: selectedCommunities,
        },
        {
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (percentCompleted >= 95) {
              clearInterval(progressSimulation);
            }
            setProgressPercentage(percentCompleted);
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        downloadReport();
         // Log the report activity after successful report generation
    if (userEmail) {
      // Map the GPNums to their full community names
      const communityNames = originalSelectedCommunities.map(gpNum => {
        const community = communities.find(c => c.value === gpNum);
        return community ? `${community.value} - ${community.label}` : gpNum;
      }).join(", ");

      logReportActivity(userEmail, { label: "Cross Company GL Account Analysis Report (Enhanced)"}, communityNames);
    }
      })
      .catch((error) => {
        clearInterval(progressSimulation);
        console.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false); // End loading after 5 seconds
        }, 5000); // Wait for 5 seconds after reaching 100%
      });
  };

  return (
    <div className="form-container">
      <h5 className="form-title">
        GL Account Analysis Enhanced (Cross Company)
      </h5>
      {isLoading && (
        <div className="loading-bar-container">
          <div
            className="loading-bar"
            style={{ width: `${progressPercentage}%` }}
          ></div>
          <span>{progressPercentage}%</span>
        </div>
      )}

      <div className="form-element">
        <label htmlFor="first-gl-account">First GL Account</label>
        <Select
          id="first-gl-account"
          options={glAccounts}
          onChange={handleFirstGlAccountChange}
          value={glAccounts.find((account) => account.value === firstGlAccount)}
          placeholder="Select First GL Account"
        />
      </div>

      <div className="form-element">
        <label htmlFor="last-gl-account">Last GL Account</label>
        <Select
          id="last-gl-account"
          options={glAccounts}
          onChange={handleLastGlAccountChange}
          value={glAccounts.find((account) => account.value === lastGlAccount)}
          placeholder="Select Last GL Account"
        />
      </div>
      <div className="form-element">
        <label htmlFor="first-posting-date">First Posting Date</label>

        <input
          type="text"
          id="first-posting-date"
          className="input"
          value={
            value ? value.toLocaleDateString() : "Select First Posting Date"
          }
          onClick={() => setShowCalendar1(!showCalendar1)}
          readOnly
        />

        {showCalendar1 && (
          <Calendar
            onChange={(newValue) => {
              setValue(newValue);
              setShowCalendar1(false);
            }}
            value={value}
          />
        )}
      </div>

      <div className="form-element">
        <label htmlFor="last-posting-date">Last Posting Date</label>
        <input
          type="text"
          id="last-posting-date"
          className="input"
          value={
            value2 ? value2.toLocaleDateString() : "Select Last Posting Date"
          }
          onClick={() => setShowCalendar2(!showCalendar2)}
          readOnly
        />

        {showCalendar2 && (
          <Calendar onChange={handleSecondPostingDateChange} value={value2} />
        )}
      </div>

      {/* form element for owners*/}
      <div className="form-element">
        <label htmlFor="select-owner">Owner</label>
        <select
          id="select-owner"
          className="select"
          onChange={(e) => handleOwnerChange({ value: e.target.value })}
        >
          <option value="" disabled selected>
            Select Owner
          </option>
          {owners.map((owner) => (
            <option value={owner.value}>{owner.label}</option>
          ))}
        </select>
      </div>

      {/* form element for communities*/}

      <div className="form-element communities-container">
        <div className="community-select">
          <label htmlFor="select-community">Select Community(ies)</label>

          {/* Add the "Include Prior Owners" checkbox and label */}
          <div style={{ marginLeft: "4px", marginTop: "10px" }}>
            <input
              type="checkbox"
              id="include-prior-owners"
              checked={includePriorOwners}
              onChange={handleIncludePriorOwners}
            />
            <label htmlFor="include-prior-owners" style={{ marginLeft: "4px" }}>
              Include Prior Owners
            </label>
          </div>

          <Select
            id="community"
            options={filteredCommunities}
            isMulti
            onChange={handleCommunityChange}
            closeMenuOnSelect={false}
            placeholder="Select Community(ies)"
            value={selectedCommunities.map((community) =>
              filteredCommunities.find((option) => option.value === community)
            )}
          />
        </div>
      </div>

      {/* <div className="form-element">
        <div className="checkboxes-container">
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="open-in-web"
              checked={openInWeb}
              onChange={(e) => setOpenInWeb(e.target.checked)}
            />
            <label htmlFor="open-in-web" style={{ marginLeft: "4px" }}>
              Open in web
            </label>
          </div>
        </div>
            </div> */}

      {/* ... form elements ... */}
      <div className="form-element button-container">
        <button className="half-width-button" onClick={handleProcessClick}>
          Process
        </button>

        {/* <button className="half-width-button">Return to Menu</button> */}
      </div>
    </div>
  );
};

export default FrmGlDetail;
