import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const fetchAndExportCashFlowData = async (
  reportMonth,
  reportYear,
  gpNum,
  community
) => {
  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    // Fetch data from the API endpoint
    const response = await fetch(`${apiUrl}/spCashFlows`);
    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText} (${response.status})`);
    }

    // Parse the JSON data returned by the API
    const data = await response.json();

    // Desired structure of the data in the report
    const desiredOrder = [
      "Statement of Cash Flows",
      "",
      "Net Income From Operations",
      "",
      "Depreciation & Amortization",
      "",
      "Net Cash Flow From Operations",
      "",
      "Sources (Uses) of Cash:",
      "Accounts Receivable",
      "Prepaids",
      "Deposits",
      "Capex Reserves",
      "Accounts Payable",
      "Due To Frontier",
      "Payroll & Related Liabilities",
      "Insurance",
      "",
      "Total Sources (Uses) of Cash",
      "",
      "",
      "Net Cash Flow From Operating Activities",
      "",
      "",
      "Cash Flow From Investing Activities",
      "",
      "Equipment",
      "",
      "Net Cash Flow From Investing Activities",
      "",
      "",
      "",
      "Cash Flow From Financing Activities",
      "",
      "Notes Payable",
      "",
      "Net Cash Flow From Financing Activities",
      "",
      "",
      "",
      "Cash at Beginning Of Period",
      "",
      "Net Increase (Decrease) in Cash",
      "",
      "Cash at End of Period",
    ];

    // Map the desired structure to the available data
    const orderedData = desiredOrder.map((subcategory) => {
      const row = data.find((r) => r.Subcategory === subcategory);
      return row || { Subcategory: subcategory, CurrentAmount: 0, PriorAmount: 0 };
    });

    // Create a new Excel workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Statement of Cash Flows");

    // Format the month name for display
    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString("en-us", {
      month: "long",
    });

    // Headers for the report
    const headers = [
      [` ${community}`],
      ["Statement of Cash Flows"],
      [`For The ${reportMonth} Months Ending ${monthName}, ${reportYear}`],
      [],
      [],
    ];

    // Add header rows to the worksheet with appropriate styles
    headers.forEach((headerRow, index) => {
      const row = worksheet.addRow(headerRow);
      row.height = 25;
      row.getCell(1).value = headerRow[0];
      row.getCell(1).font = {
        size: index === 0 ? 16 : 14,
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
      row.getCell(1).alignment = { horizontal: "center" };

      if (index <= 2) {
        row.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "013220" },
        };
      }

      worksheet.mergeCells(index + 1, 1, index + 1, 3);
    });

    // Add column headers for "Month Name" and "Year To Date"
    const columnHeaderRow = worksheet.addRow(["", monthName, "Year To Date"]);
    columnHeaderRow.eachCell((cell, colNumber) => {
      if (colNumber > 1) {
        cell.font = { bold: true, color: { argb: "FF008000" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFC0C0C0" },
        };
      }
    });

    // Add rows containing the data
    orderedData.forEach((row, rowIndex) => {
      const rowData = [
        row.Subcategory,
        row.Subcategory === "" ? "" : Math.abs(row.CurrentAmount) || 0,
        row.Subcategory === "" ? "" : Math.abs(row.PriorAmount) || 0,
      ];

      const excelRow = worksheet.addRow(rowData);

      // Apply bold formatting and borders to specific rows
      if ([6, 18, 21, 28, 36, 44].includes(rowIndex)) {
        excelRow.eachCell((cell) => {
          cell.font = { bold: true };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      }

      // Apply currency formatting to numerical cells
      excelRow.getCell(2).numFmt = row.CurrentAmount < 0 ? "($#,##0)" : "$#,##0";
      excelRow.getCell(3).numFmt = row.PriorAmount < 0 ? "($#,##0)" : "$#,##0";
    });

    // Set appropriate column widths
    worksheet.columns = [{ width: 35 }, { width: 15 }, { width: 15 }];

    // Freeze the first 6 rows (header rows)
    worksheet.views = [{ state: "frozen", ySplit: 6 }];

    // Generate and save the Excel file
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Statement_of_Cash_Flows.xlsx");
  } catch (error) {
    console.error("Error fetching and exporting data:", error);
  }
};
