import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const fetchAndExportTTMRamOccupancyData = async (
  reportMonth,
  reportYear,
  gpNum,
  communityName
) => {
 


    try {
      // Access the API URL environment variable with the correct prefix
      const apiUrl = process.env.REACT_APP_API;
      if (!apiUrl) {
        throw new Error("API URL not specified in the environment variables");
      }
  
      const response = await fetch(`${apiUrl}/uspttmramoccupancy`);
      const dataSets = await response.json();



    if (
      !Array.isArray(dataSets) ||
      !Array.isArray(dataSets[0]) ||
      !dataSets[0].length
    ) {
      throw new Error("Invalid data structure");
    }

    const { CommName } = dataSets[0][0];

    const workbook = new ExcelJS.Workbook();
    const supportDetailWorksheet = workbook.addWorksheet(
      `${gpNum} - SupportDetail`
    );
    addHeadersAndData(
      supportDetailWorksheet,
      CommName,
      dataSets,
      reportMonth,
      reportYear,
      communityName,
      gpNum,
      true
    );

    const commNameWorksheet = workbook.addWorksheet(CommName);
    addHeadersAndData(
      commNameWorksheet,
      CommName,
      dataSets,
      reportMonth,
      reportYear,
      communityName,
      gpNum,
      false
    );

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${CommName}_TTM_Rolling_Occupancy_Report.xlsx`);
  } catch (error) {
    console.error(
      "Error fetching and exporting TTM RAM Occupancy data:",
      error
    );
  }
};
function addHeadersAndData(worksheet, commName, dataSets, reportMonth, reportYear, community, gpNum, applyData, isSupportDetailWorksheet) {

  const headerFont = { name: "Calibri", family: 4, size: 11, bold: true };
  const titleFont = { ...headerFont, size: 14 };

  // Define formattedDateTime
  const now = new Date();
  const formattedDateTime = now.toLocaleString("en-US", { hour12: false });

  worksheet.columns = [
    { key: "A", width: 12 },
    { key: "B", width: 12 },
    { key: "C", width: 15 },
    { key: "D", width: 15 },
    { key: "E", width: 15 },
    { key: "F", width: 15 },
    { key: "G", width: 15 },
    { key: "H", width: 15 },
    { key: "I", width: 15 },
    { key: "J", width: 15 },
    { key: "K", width: 15 },
    { key: "L", width: 15 },
    { key: "M", width: 12 },
    { key: "N", width: 12 },
    { key: "O", width: 15 },
    { key: "P", width: 15 },
    { key: "Q", width: 15 },
    { key: "R", width: 15 },
    { key: "S", width: 15 },
    { key: "T", width: 15 },
    { key: "U", width: 15 },
    { key: "V", width: 15 },
    { key: "W", width: 15 },
    { key: "X", width: 15 },
    { key: "Y", width: 15 },
    { key: "Z", width: 15 },
    { key: "AA", width: 15 },
    { key: "AB", width: 15 },
    { key: "AC", width: 15 },
    { key: "AD", width: 20 },
    { key: "AE", width: 20 },
    { key: "AF", width: 10 },
    { key: "AG", width: 10 },
    { key: "AH", width: 15 },
    { key: "AI", width: 15 },
    { key: "AJ", width: 15 },
    { key: "AK", width: 15 },
    { key: "AL", width: 15 },
    { key: "AM", width: 15 },
    { key: "AN", width: 15 },
    { key: "AO", width: 15 },
    { key: "AP", width: 15 },
    { key: "AQ", width: 15 },
    { key: "AR", width: 15 },
    { key: "AS", width: 15 },
    { key: "AT", width: 15 },
    { key: "AU", width: 15 },
    { key: "AV", width: 15 },
    { key: "AW", width: 15 },
    { key: "AX", width: 15 },
    { key: "AY", width: 15 },
    { key: "AZ", width: 15 },
    { key: "BA", width: 15 },
    { key: "BB", width: 15 },
    { key: "BC", width: 15 },
    { key: "BD", width: 15 },
    { key: "BE", width: 15 },
    { key: "BF", width: 15 },
    { key: "BG", width: 15 },
    { key: "BH", width: 15 },
    { key: "BI", width: 15 },
    { key: "BJ", width: 20 },
    { key: "BK", width: 15 },
    { key: "BL", width: 15 },
    { key: "BM", width: 15 },
    { key: "BN", width: 15 },
    { key: "BO", width: 15 },
    { key: "BP", width: 15 },
    { key: "BQ", width: 15 },
    { key: "BW", width: 15 },
    { key: "BX", width: 15 },
    { key: "BY", width: 15 },
    { key: "BZ", width: 15 },
    { key: "CA", width: 15 },
  ];

  worksheet.getCell("A1").value = `Supporting Details for ${commName}`;
  worksheet.getCell("A2").value = `Run Time: ${formattedDateTime}`;
  worksheet.getCell("A3").value = "";
  worksheet.getCell("A4").value = `Day To Day Census Detail For ${commName}`;
  worksheet.getCell("A5").value =
    "*Move-Out dates are 1 day after the AR move-out date for this report";
  worksheet.getCell("O5").value = `Rolling Census Detail For ${commName}`;
  worksheet.getCell("AB4").value = `Move-Ins/Move-Outs Detail For ${commName}`;
  worksheet.getCell("AB5").value =
    "*Move-Out Dates are one day AFTER the AR move-out date for this report";
  worksheet.getCell("AL5").value = `Care Type Changes Detail For ${commName}`;
  worksheet.getCell("AX5").value = `Possible Errors Detail For ${commName}`;
  worksheet.getCell(
    "BJ5"
  ).value = `Move-Out Reasons Pivot Table For ${commName}`;
  worksheet.getCell("O5").font = titleFont;
  worksheet.getRow(1).font = titleFont;
  for (let i = 2; i <= 5; i++) {
    worksheet.getRow(i).font = headerFont;
  }

  // Check if we are working with the commNameWorksheet and not the supportDetailWorksheet
  if (worksheet.name === commName && !isSupportDetailWorksheet) {
    // Clear the cells that need to be empty
    const cellsToClear = ['A4', 'A5', 'O5', 'AB4', 'AB5', 'AL5', 'AX5', 'BJ5'];
    cellsToClear.forEach(cell => {
      worksheet.getCell(cell).value = null;
    });

    // Adjust the width of column A
    worksheet.getColumn('A').width = 24; // Double the original width

    // Assuming dataSet[1] contains the data in the structure as provided in your message
    const dataSet = dataSets[1];

    // Map the keys to the column letters
    const columnMappings = {
      'RowDescription': 'A', 'Mo1Value': 'B', 'Mo2Value': 'C', 'Mo3Value': 'D',
      'Mo4Value': 'E', 'Mo5Value': 'F', 'Mo6Value': 'G', 'Mo7Value': 'H',
      'Mo8Value': 'I', 'Mo9Value': 'J', 'Mo10Value': 'K', 'Mo11Value': 'L', 'Mo12Value': 'M'
    };

    // Set header row values based on the mappings
    const headerRow = worksheet.getRow(6);
    Object.values(columnMappings).forEach((columnLetter, index) => {
      headerRow.getCell(columnLetter).value = Object.keys(columnMappings)[index];
      headerRow.getCell(columnLetter).font = headerFont;
    });

    // Start adding data from row 7
    dataSet.forEach((dataRow, rowIndex) => {
      const row = worksheet.getRow(rowIndex + 7);
      Object.entries(columnMappings).forEach(([key, columnLetter]) => {
        row.getCell(columnLetter).value = dataRow[key];
      });
    });

    // Make sure other cells from N onwards are empty
    const rowsToProcess = dataSet.length + 7;
    for (let i = 7; i <= rowsToProcess; i++) {
      for (let col = 14; col <= worksheet.columnCount; col++) {
        worksheet.getRow(i).getCell(col).value = null;
      }
    }
  }


  if (applyData) {
    const columnHeaders = [
      "CareType",
      "Period",
      "CensusDate",
      "PrimaryCensus",
      "DayMoveIns",
      "DayMoveOuts",
      "CareTypeTransferIns",
      "CareTypeTransferOuts",
      "DayMoveInsDetail",
      "DayMoveOutsDetail",
      "DayTransferInDetail",
      "DayTransferOutDetail",
    ];
    worksheet.getRow(6).values = columnHeaders;
    worksheet.getRow(6).font = headerFont;

    const additionalHeaders = {
      O: "CareType",
      P: "Period",
      Q: "MoveIns",
      R: "MoveOuts",
      S: "PreviousMonthMoveOutFirst",
      T: "FDOMMoveIns",
      U: "EOMMoveOuts",
      V: "PreviousMonthLDOMCareTypeChangeOnFirst",
      W: "MidMonthCareTypeChange",
      X: "StartingCensus",
      Y: "EndingCensus",
    };
    for (let [column, header] of Object.entries(additionalHeaders)) {
      worksheet.getCell(`${column}6`).value = header;
      worksheet.getCell(`${column}6`).font = headerFont;
    }

    const moveInOutHeaders = {
      AB: "Period",
      AC: "CensusDate",
      AD: "Activity",
      AE: "FullName",
      AF: "RoomNum",
      AG: "CL",
      AH: "CareType",
      AI: "Reason",
    };
    for (let [column, header] of Object.entries(moveInOutHeaders)) {
      worksheet.getCell(`${column}6`).value = header;
      worksheet.getCell(`${column}6`).font = headerFont;
    }

    const careTypeHeaders = {
      AL: "Period",
      AM: "FullName",
      AN: "ChangeType",
      AO: "OldCareType",
      AP: "OldApartment",
      AQ: "OutDate",
      AR: "NewCareType",
      AS: "NewApartment",
      AT: "InDate",
      AU: "Note",
    };
    for (let [column, header] of Object.entries(careTypeHeaders)) {
      worksheet.getCell(`${column}6`).value = header;
      worksheet.getCell(`${column}6`).font = headerFont;
    }

    const possibleErrosHeaders = {
      AX: "Period",
      AY: "FullName",
      AZ: "ChangeType",
      BA: "OldCareType",
      BB: "OldApartment",
      BC: "OutDate",
      BD: "NewCareType",
      BE: "NewApartment",
      BF: "InDate",
      BG: "Note",
    };
    for (let [column, header] of Object.entries(possibleErrosHeaders)) {
      worksheet.getCell(`${column}6`).value = header;
      worksheet.getCell(`${column}6`).font = headerFont;
    }

    const censusData = dataSets[2];
    const additionalData = dataSets[3];
    const moveInOutData = dataSets[4];
    const careTypeData = dataSets[5];
    const possibleErrosData = dataSets[6];
    const moveOutReasonsData = dataSets[8];
    const dynamicHeaders =
      moveOutReasonsData.length > 0 ? Object.keys(moveOutReasonsData[0]) : [];
    dynamicHeaders.shift(); // Remove the 'Reason' key, which is static

    // Setting dynamic headers starting from BK (BJ is static for 'Reason')
    dynamicHeaders.forEach((header, index) => {
      const letter = columnIndexToLetter(63 + index); // 63 corresponds to BK
      worksheet.getCell(`${letter}6`).value = header;
    });

    const maxRowLength = Math.max(
      censusData.length,
      additionalData.length,
      moveInOutData.length,
      careTypeData.length,
      possibleErrosData.length,
      moveOutReasonsData.length
    );

    for (let i = 0; i < maxRowLength; i++) {
      const row = worksheet.getRow(7 + i);
      const censusRowData = censusData[i] || {};
      const additionalRowData =
        i < additionalData.length ? additionalData[i] : {};
      const moveInOutRowData = i < moveInOutData.length ? moveInOutData[i] : {};
      const careTypeRowData = i < careTypeData.length ? careTypeData[i] : {};
      const possibleErrosRowData =
        i < possibleErrosData.length ? possibleErrosData[i] : {};

      row.values = {
        A: censusRowData.CareType || "",
        B: censusRowData.Period ? censusRowData.Period.substring(0, 10) : "",
        C: censusRowData.CensusDate
          ? censusRowData.CensusDate.substring(0, 10)
          : "",
        D: censusRowData.PrimaryCensus || "",
        E: censusRowData.DayMoveIns || 0,
        F: censusRowData.DayMoveOuts || 0,
        G: censusRowData.CareTypeTransferIns || 0,
        H: censusRowData.CareTypeTransferOuts || 0,
        I: censusRowData.DayMoveInsDetail || "",
        J: censusRowData.DayMoveOutsDetail || "",
        K: censusRowData.DayTransferInDetail || "",
        L: censusRowData.DayTransferOutDetail || "",
        O: additionalRowData.CareType || "",
        P: additionalRowData.ARMonth
          ? additionalRowData.ARMonth.substring(0, 10)
          : "",
        Q: i < additionalData.length ? additionalRowData.MoveIns || 0 : null,
        R: i < additionalData.length ? additionalRowData.MoveOuts || 0 : null,
        S:
          i < additionalData.length
            ? additionalRowData.PreviousMonthMoveOutFirst || 0
            : null,
        T:
          i < additionalData.length ? additionalRowData.FDOMMoveIns || 0 : null,
        U:
          i < additionalData.length ? additionalRowData.EOMMoveOuts || 0 : null,
        V:
          i < additionalData.length
            ? additionalRowData.PreviousMonthLDOMCareTypeChangeOnFirst || 0
            : null,
        W:
          i < additionalData.length
            ? additionalRowData.MidMonthCareTypeChange || 0
            : null,
        X:
          i < additionalData.length
            ? additionalRowData.StartingCensus || 0
            : null,
        Y:
          i < additionalData.length
            ? additionalRowData.EndingCensus || 0
            : null,
        AB: moveInOutRowData.Period
          ? moveInOutRowData.Period.substring(0, 10)
          : "",
        AC: moveInOutRowData.CensusDate
          ? moveInOutRowData.CensusDate.substring(0, 10)
          : "",
        AD: moveInOutRowData.Activity || "",
        AE: moveInOutRowData.FullName || "",
        AF: moveInOutRowData.RoomNum || "",
        AG: moveInOutRowData.CL || "",
        AH: moveInOutRowData.CareType || "",
        AI: moveInOutRowData.Reason || "",
        AL: careTypeRowData.Period
          ? careTypeRowData.Period.substring(0, 10)
          : "",
        AM: careTypeRowData.FullName || "",
        AN: careTypeRowData.ChangeType || "",
        AO: careTypeRowData.OldCareType || "",
        AP: careTypeRowData.OldApartment || "",
        AQ: careTypeRowData.OutDate
          ? careTypeRowData.OutDate.substring(0, 10)
          : "",
        AR: careTypeRowData.NewCareType || "",
        AS: careTypeRowData.NewApartment || "",
        AT: careTypeRowData.InDate || "",
        AU: careTypeRowData.Note || "",
        AX: possibleErrosRowData.Period
          ? possibleErrosRowData.Period.substring(0, 10)
          : "",
        AY: possibleErrosRowData.FullName || "",
        AZ: possibleErrosRowData.ChangeType || "",
        BA: possibleErrosRowData.OldCareType || "",
        BB: possibleErrosRowData.OldApartment || "",
        BC: possibleErrosRowData.OutDate
          ? possibleErrosRowData.OutDate.substring(0, 10)
          : "",
        BD: possibleErrosRowData.NewCareType || "",
        BE: possibleErrosRowData.NewApartment || "",
        BF: possibleErrosRowData.InDate
          ? possibleErrosRowData.InDate.substring(0, 10)
          : "",
        BG: possibleErrosRowData.Note || "",
      };

      // Assign moveOutReasons data
      const moveOutReasonRowData = moveOutReasonsData[i] || {};
      worksheet.getCell("BJ6").value = "Move-out Reason";
      row.getCell("BJ").value = moveOutReasonRowData["Reason"] || "";
      dynamicHeaders.forEach((header, index) => {
        if (index > 0) {
          const letter = columnIndexToLetter(63 + index); // BK starts at index 63
          row.getCell(letter).value = moveOutReasonRowData[header] || null;
        }
        const letter = columnIndexToLetter(63 + index); // BK starts at index 63
        if (letter === "BW") {
          // Here we are specifically checking for column 'BW' to ensure data is assigned
          row.getCell(letter).value = moveOutReasonRowData[header] || null;
        }
      });

      row.commit();
    }
  }
}
function columnIndexToLetter(columnIndex) {
  let letter = "";
  while (columnIndex > 0) {
    let remainder = (columnIndex - 1) % 26;
    letter = String.fromCharCode(65 + remainder) + letter;
    columnIndex = Math.floor((columnIndex - remainder) / 26);
  }
  return letter;
}
