import React from "react";
import "../App.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Home = () => {
    return (
        <main className="container home-content">
            <div className="image-container">
            <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    infiniteLoop
                    autoPlay
                    interval={5000}
                    transitionTime={800}
                >
                    <div>
                        <img
                            src="https://images.unsplash.com/photo-1422015347944-9dd46d16bd0b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80"
                            alt="Senior citizen enjoying a hobby"
                            className="home-image"
                        />
                    </div>
                    <div>
                        <img
                             src="https://images.unsplash.com/photo-1603792907191-89e55f70099a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80"
                             alt="elderly fun guy"
                             className="home-image"
                        />
                    </div>
                    <div>
                        <img
                            src="https://images.unsplash.com/photo-1581579438530-e203e7572d95?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80"
                            alt="elderly care"
                            className="home-image"
                        />
                    </div>
                </Carousel>
            </div>
        </main>
    );
};

export default Home;
