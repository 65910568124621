import React, { useState, useEffect, useCallback, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Menu, NightsStay, WbSunny, Home } from "@mui/icons-material";
import "../App.css";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";


const Navcomponent = ({ toggleDrawer }) => {
  const [darkTheme, setDarkTheme] = useState(false);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const userEmail = accounts.length > 0 ? accounts[0].username : "";
  //const [inactiveTimeout, setInactiveTimeout] = useState(null);
  const inactiveTimeoutRef = useRef(null);


  const handleLogin = () => {
    instance
      .loginPopup()
      .then((response) => {
        // Fetch the userEmail after successful login to ensure you have the latest value
        const loggedInEmail = response.account.username;
        logUserActivity(loggedInEmail, "login"); // Log user login activity
        window.location.assign("");
      })
      .catch((err) => {
        console.error("Error during login:", err);
      });
  };
  // Wrap handleLogout in useCallback
  const handleLogout = useCallback(() => {
    instance.logoutRedirect({});
    logUserActivity(userEmail, "logout"); // Log user logout activity
  }, [instance, userEmail]); // Dependencies of handleLogout

  // Using useCallback to memoize resetTimer function
  const resetTimer = useCallback(() => {
    clearTimeout(inactiveTimeoutRef.current);
    inactiveTimeoutRef.current = setTimeout(handleLogout, 15 * 60 * 1000); // 5 minutes
  }, [handleLogout]);

  useEffect(() => {
    // Set the initial timeout
    resetTimer();

    // Event listeners for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    // Cleanup function
    return () => {
      clearTimeout(inactiveTimeoutRef.current);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, [resetTimer]);

  const logUserActivity = (userEmail, activityType) => {
    const activityTime = new Date().toISOString();
    fetch("/api/user-access", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userEmail, activityType, activityTime }),
    })
      .then((res) => res.json())
      .then((data) => console.log(data.message))
      .catch((err) => console.error("Error logging user activity:", err));
  };

  const toggleTheme = () => {
    setDarkTheme((prevTheme) => !prevTheme);

    if (document.body) {
      document.body.classList.toggle("dark-theme", !darkTheme);
    }

    const navTextElement = document.querySelector(".nav-text");
    if (navTextElement) {
      navTextElement.classList.toggle("dark-mode-text", !darkTheme);
    }
  };

  return (
    
    <Navbar className="bg-custom" variant="dark">
      <div className="nav-container">
        {isAuthenticated && (
          <Nav className="nav-start">
            <Nav.Link
              href="/"
              onClick={(event) => {
                event.preventDefault();
                toggleDrawer();
              }}
            >
              <Menu className="nav-icon" />
              <span className="nav-text">FinRep 1.0</span>
            </Nav.Link>
          </Nav>
        )}
        <Nav className="nav-middle">
          {isAuthenticated && (
            <Nav.Link
              onClick={(event) => {
                event.preventDefault();
                localStorage.removeItem("selectedOptionValue");
                window.location.reload();
              }}
            >
              <Home className="nav-icon" />
            </Nav.Link>
          )}
        </Nav>
        <Nav className="nav-end">
          <div className="user-section">
            {isAuthenticated ? (
              <>
                <span className="nav-text">Signed in as: {userEmail}</span>
                <Nav.Link onClick={handleLogout} className="auth-link">
                  Logout
                </Nav.Link>
              </>
            ) : (
              <Nav.Link onClick={handleLogin} className="auth-link">
                Login
              </Nav.Link>
            )}
          </div>
          <Nav.Link onClick={toggleTheme}>
            {darkTheme ? (
              <WbSunny className="nav-icon theme-icon" />
            ) : (
              <NightsStay className="nav-icon theme-icon" />
            )}
          </Nav.Link>
        </Nav>{" "}
      </div>
    </Navbar>
  );
};

export default Navcomponent;
