import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const fetchAndExportLenderSummaryData = async (
  reportMonth,
  reportYear,
  gpNum,
  community
) => {

  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/usplenderreport`);
    let data = await response.json();

    // Filter out unwanted properties from the data
    data = data.map((item) => {
      const { ISSort1, ISSort2, ExcelFormattingCode, ...filteredItem } = item;
      return filteredItem;
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Lender Report Summary');

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      'en-us',
      { month: 'long' }
    );

    const headers = [
      ` ${community}`,
      'Lender Summary',
      `For The 12 Months Ending ${monthName}, ${reportYear}`,
    ];

    headers.forEach((header, index) => {
      const row = worksheet.addRow([]);
      row.height = 25;
      const cell = row.getCell(2);
      cell.value = header;
      cell.font = {
        size: index === 0 ? 16 : 14,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };
      cell.alignment = { horizontal: 'center' };

      if (index <= 2) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '013220' },
        };
      }

      worksheet.mergeCells(index + 1, 2, index + 1, 14);
    });

    // Add month headers
    const monthRow = worksheet.addRow([]);
    ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'].forEach(
      (col) => {
        monthRow.getCell(col).font = { bold: true };
      }
    );
    worksheet.mergeCells(5, 2, 5, 14);

    // Add column headers
    const columnHeaderRow = worksheet.addRow([
      'AccountClass',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
      'January',
      'TOTAL',
    ]);

    columnHeaderRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add data rows
    data.forEach((rowData) => {
      const row = worksheet.addRow(Object.values(rowData));
  
      // Apply currency formatting to numeric cells
      row.eachCell((cell, cellNumber) => {
        if (typeof cell.value === 'number') {
          // Adjust the format here
          cell.numFmt = cell.value < 0 ? '($#,##0.00_);($#,##0.00)' : '$#,##0.00';
        }
      });
    });
  
    // Adjust column widths
    worksheet.getColumn(1).width = 25;
    Array(13).fill().forEach((_, index) => {
      worksheet.getColumn(index + 2).width = 13;
    });

    // Freeze or pin the first 6 rows (header rows)
    worksheet.views = [{ state: 'frozen', ySplit: 6 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Lender_Report_Summary.xlsx');
  } catch (error) {
    console.error('Error fetching and exporting data:', error);
  }
};
