import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const fetchAndExportAPAgingData = async (selectedOwner, selectedEndDate) => {
    try {
        // Access the API URL environment variable with the correct prefix
        const apiUrl = process.env.REACT_APP_API;
        if (!apiUrl) {
            throw new Error("API URL not specified in the environment variables");
        }

        const response = await fetch(`${apiUrl}/apagingdata`);
        const data = await response.json();

        if (!Array.isArray(data) || data.length === 0) {
            throw new Error('Data is empty or not in the expected format');
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('AP Aging Report');

        const ownerName = selectedOwner.label || selectedOwner;
        const asOfDate = selectedEndDate.toISOString().split('T')[0] || new Date().toISOString().split('T')[0];

        const headers = [
            `AP Aging Report for ${ownerName}`,
            `Run Time: ${new Date().toLocaleString()}`,
            `As of Date: ${asOfDate}`,
            ''
        ];

        headers.forEach((header, index) => {
            const row = worksheet.getRow(index + 1);
            row.getCell(1).value = header;
            if (index === 0) {
                row.font = { size: 18, bold: true };
                row.alignment = { horizontal: 'center' };
                worksheet.mergeCells(`A1:G1`);
            } else {
                row.font = { size: 11, bold: false };
                row.alignment = { horizontal: 'left' };
            }
            row.height = 20;
        });

        const columnHeaders = ["GPNum", "Community Name", "Owner", "Vendor", "Total", "0-30 Days", "31-60 Days", "61-90 Days", "91-120 Days", "Over 120 Days"];
        const columnHeaderRow = worksheet.addRow(columnHeaders);
        columnHeaderRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
        });

        data.forEach(item => {
            const row = worksheet.addRow([
                item.GPNum,
                item.CommunityName,
                item.Owner,
                item.VendorName,
                item.Total,
                item.Mo1,
                item.Mo2,
                item.Mo3,
                item.Mo4,
                item.Mo5
            ]);
            // Apply currency formatting to columns B through G
            for (let i = 2; i <= 7; i++) {
                row.getCell(i).numFmt = '$#,##0.00;($#,##0.00)';
            }
        });

        // Adjust column widths and apply the earlier specified adjustments
        worksheet.columns.forEach((column, index) => {
            let baseWidth = column.values.filter(v => typeof v === 'string').map(v => v.length).reduce((a, b) => Math.max(a, b), 8);
            if (index === 0) { // First column (A), adjust width as before
                column.width = baseWidth * 0.9;
            } else if (index === 1) { // Second column (B), make width twice its current setting
                column.width = baseWidth * 1.1; // This is the adjusted line
            } else { // Other columns, adjust width as before
                column.width = baseWidth * 0.6;
            }
        });

        worksheet.views = [{ state: 'frozen', ySplit: columnHeaderRow.number }];

        const excelBuffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `AP_Aging_Report_${ownerName.replace(/\s+/g, '_')}_${asOfDate}.xlsx`);
    } catch (error) {
        console.error('Error fetching and exporting AP Aging data:', error);
    }
};
