import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const addHeaderRows = (worksheet, data) => {
  const headers = [
    `${data[0].FacName}`,
    worksheet.name,
    data[0].Period
  ];

  headers.forEach((header, index) => {
    const row = worksheet.addRow([]);
    row.height = 25;
    const cell = row.getCell(2);
    cell.value = header;
    cell.font = {
      size: index === 0 ? 16 : 14,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };
    cell.alignment = { horizontal: 'center' };

    if (index <= 2) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '013220' },
      };
    }

    worksheet.mergeCells(index + 1, 2, index + 1, Object.keys(data[0]).length + 1);
  });
};

export const fetchAndExportMimoData = async () => {
 

    try {
      // Access the API URL environment variable with the correct prefix
      const apiUrl = process.env.REACT_APP_API;
      if (!apiUrl) {
        throw new Error("API URL not specified in the environment variables");
      }
  
      const response = await fetch(`${apiUrl}/mimo`);
      const data = await response.json();

    const workbook = new ExcelJS.Workbook();
    const worksheets = [
      { name: "Move-In", data: data[0] },
      { name: "Move-Out", data: data[1] },
    ];

    worksheets.forEach(({ name, data }) => {
      const worksheet = workbook.addWorksheet(name);
      
      // Add custom headers
      addHeaderRows(worksheet, data);

      const headers = Object.keys(data[0]);
      
      // Add column headers and style them
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell(cell => {
        cell.font = { bold: true };
      });

      // Add data
      data.forEach(rowData => {
        worksheet.addRow(Object.values(rowData));
      });

      // Adjust column widths
      headers.forEach((_, index) => {
        worksheet.getColumn(index + 1).width = 15;
      });

      // Freeze headers
      worksheet.views = [{ state: 'frozen', ySplit: 4 }];
    });

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Mimo_Report.xlsx');
  } catch (error) {
    console.error('Error fetching and exporting data:', error);
  }
};
