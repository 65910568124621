import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const fetchAndExportOperationDetailData = async (
  reportMonth,
  reportYear,
  community,
) => {
    try {
      // Access the API URL environment variable with the correct prefix
      const apiUrl = process.env.REACT_APP_API;
      if (!apiUrl) {
        throw new Error("API URL not specified in the environment variables");
      }
  
      const response = await fetch(`${apiUrl}/uspstatementofoperations`);
      const data = await response.json();
    

    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Data is empty or not in expected format');
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Statement Of Operations Detail");

    const monthName = new Date(reportYear, reportMonth - 1).toLocaleString(
      "en-us",
      { month: "long" }
    );

    const headers = [
      `${community}`,
      "Statement Of Operations Detail",
      `For The 12 Months Ending ${monthName}, ${reportYear}`,
      ' ',
    ];

     // Add header rows at the top of the worksheet
  headers.forEach((header, index) => {
    const row = worksheet.getRow(index + 1);
    row.values = [header]; // Assign the header to the first cell
    row.font = { size: 14, bold: true }; // Make the font bold
    row.alignment = { horizontal: "left" }; // Align text to the left
    row.height = 20; // Set a custom height for the header row
  });

  // Set the column widths based on the header content
  headers.forEach((header, index) => {
    worksheet.getColumn(index + 1).width = header.length + 5; // Adjust the width to fit the header content
  });


    // Using the API data for column headers
    const columnHeaderData = Object.keys(data[0]).filter(key => key !== 'Account');

    // Add column headers
    const columnHeaderRow = worksheet.addRow(['Account', ...columnHeaderData]);
    columnHeaderRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

      // Add data rows
      data.forEach((rowData) => {
        const row = worksheet.addRow(Object.values(rowData));
  

      // Apply currency formatting to numeric cells
      row.eachCell((cell, colNumber) => {
        if (typeof cell.value === "number") {
          // Adjust the format here
          cell.numFmt = cell.value < 0 ? "($#,##0.00_);($#,##0.00)" : "$#,##0.00;[Red]$-#,##0.00";
          // Set the decimal points to be hidden
          if (!cell.isActiveCell) {
            cell.numFmt = "$#,##0;[Red]$-#,##0";
          }
        }
      });

      // Check for '___Total' and make modifications
      if (row.getCell(2).value && row.getCell(2).value.startsWith("___Total")) {
        row.getCell(2).value = row.getCell(2).value.replace("___", ""); // Remove '___'
        row.eachCell((cell) => {
          cell.font = { bold: true }; // Bold the entire row
        });
      }
    });

    // Adjust column widths
    worksheet.getColumn(1).width = 23;
    Array.from({ length: columnHeaderData.length + 1 }).forEach((_, index) => {
      worksheet.getColumn(index + 2).width = 12;
    });

    // Freeze or pin the first 4 rows (header rows)
    worksheet.views = [{ state: "frozen", ySplit: 4 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Statement_Of_Operations_Detail.xlsx");
  } catch (error) {
    console.error("Error fetching and exporting data:", error);
  }
};
