import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const fetchAndExportYTDTrialBalanceData = async (
  reportMonth,
  reportYear,
  gpNum,
  community
) => {
  try {
    // Access the API URL environment variable with the correct prefix
    const apiUrl = process.env.REACT_APP_API;
    if (!apiUrl) {
      throw new Error("API URL not specified in the environment variables");
    }

    const response = await fetch(`${apiUrl}/uspYTDTrialBalance`);
    const data = await response.json();

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("YTDTrialBalance");

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const headers = [
      `${community} -  YTDTrialBalance`,
      `For ${monthNames[reportMonth - 1]} ${reportYear}`,
      ""
    ];

    headers.forEach((header, index) => {
      const row = worksheet.addRow([]);
      row.height = 25;
      const cell = row.getCell(2);
      cell.value = header;
      cell.font = {
        size: index === 0 ? 16 : 14,
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
      cell.alignment = { horizontal: "center" };

      if (index <= 1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "013220" },
        };
      }

      worksheet.mergeCells(index + 1, 2, index + 1, 7);
    });

    const columnHeaderData = ["Yr", "Mo", "Account", "AcctDesc","BeginningBalance", "Debit", "Credit", "Period Change", "EndingBalance"];
    const columnHeaderRow = worksheet.addRow(columnHeaderData);
    columnHeaderRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    data.forEach((row) => {
      const rowData = [
        row.Yr,
        row.Mo,
        row.Account,
        row.AccountDescription,
        row.BeginningBalance < 0 ? -row.BeginningBalance : row.BeginningBalance,
        row.Debit < 0 ? -row.Debit : row.Debit,
        row.Credit < 0 ? -row.Credit : row.Credit,
        row.PeriodChange < 0 ? -row.PeriodChange : row.PeriodChange,
        row.EndingBalance < 0 ? -row.EndingBalance : row.EndingBalance
      ];
      const excelRow = worksheet.addRow(rowData, "n");
      
      for (let i = 5; i <= 7; i++) {
        excelRow.getCell(i).numFmt = rowData[i - 1] < 0 ? "($#,##0)" : "$#,##0";
      }
    });

    worksheet.getColumn(4).width = 30;
    for (let i = 5; i <= 7; i++) {
      worksheet.getColumn(i).width = 20;
    }

    worksheet.views = [{ state: 'frozen', ySplit: 4 }];

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "YTDTrialBalance.xlsx");
  } catch (error) {
    console.error("Error fetching and exporting data:", error);
  }
};
