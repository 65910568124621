import React, { useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Close, Assessment, Description, Business, ListAlt } from "@mui/icons-material";
import FrmQuickIncome from "./components/FrmQuickIncome.js";
import FrmGlDetail from "./components/FrmGlDetail";
import FrmGlActivity from "./components/FrmGlActivity.js";
import FrmCorporateReport from "./components/FrmCorporateReport";
import Navcomponent from "./components/Nav.js";
import Footer from "./components/Footer.js";
import Home from "./components/Home";
import TalaLandingPage from "./components/tala/TalaLandingPage.js";
import TalaLandingPage2 from "./components/tala/TalaLandingPage2.js";
import JobPostingPage from "./components/tala/JobPostingPage.js";
import axios from "axios";
import "./App.css";
import { config } from "./authConfig.js";

function App() {
  const [currentTenant, setCurrentTenant] = useState(localStorage.getItem("currentTenant") || "frontier");

  // Define tenant configurations dynamically based on the selected tenant
  const getTenantConfig = (tenant) => {
    return {
      auth: {
        clientId: config.tenants[tenant].clientId,
        authority: config.tenants[tenant].authority,
        redirectUri: config.tenants[tenant].redirectUri,
      },
      cache: config.cache,
    };
  };

  // Initialize MSAL instance with the current tenant configuration
  const [msalInstance, setMsalInstance] = useState(new PublicClientApplication(getTenantConfig(currentTenant)));
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  // Dynamically update the MSAL instance configuration based on the authenticated user's email domain
  useEffect(() => {
    if (account) {
      const emailDomain = account.username.split("@")[1];
      const tenant = emailDomain === "waxmanassociates.com" ? "waxman" : "frontier";

      if (tenant !== currentTenant) {
        setCurrentTenant(tenant);
        localStorage.setItem("currentTenant", tenant);
        setMsalInstance(new PublicClientApplication(getTenantConfig(tenant)));
      }
    }
  }, [account, currentTenant]);

  const options = [
    { value: 1, label: "Quick Income Statement/Balance Sheet", icon: <Assessment /> },
    { value: 2, label: "GL Account Analysis Enhanced (Cross Company)", icon: <Description /> },
    { value: 3, label: "GL Detailed Activity Report", icon: <ListAlt /> },
    { value: 4, label: "Corporate Reports", icon: <Business /> },
  ];

  const [selectedOptionValue, setSelectedOptionValue] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [allJobListingsData, setAllJobListingsData] = useState([]);

  // Fetch job data via API
  useEffect(() => {
    axios
      .get("/api/taladata")
      .then((response) => setAllJobListingsData(response.data))
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  const handleClick = (value) => {
    setSelectedOptionValue(value);
    localStorage.setItem("selectedOptionValue", value);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Maintain selected options between sessions
  useEffect(() => {
    const storedOptionValue = localStorage.getItem("selectedOptionValue");
    if (storedOptionValue) {
      setSelectedOptionValue(Number(storedOptionValue));
    } else {
      setSelectedOptionValue(null);
    }
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <Routes>
        <Route path="/tala" element={<TalaLandingPage />} />
        <Route path="/tala.org" element={<TalaLandingPage2 />} />
        <Route path="/tala.org/job-posting" element={<JobPostingPage allJobsData={allJobListingsData} />} />
        <Route path="/" element={
          <div className="App">
            <Navcomponent toggleDrawer={toggleDrawer} />
            <AuthenticatedTemplate>
              <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
                <Box sx={{ width: 250 }}>
                  <List>
                    {options.map((option) => (
                      <ListItem
                        button
                        key={option.value}
                        onClick={() => handleClick(option.value)}
                        selected={selectedOptionValue === option.value}
                      >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText primary={option.label} />
                      </ListItem>
                    ))}
                    <ListItem button onClick={toggleDrawer}>
                      <ListItemIcon>
                        <Close />
                      </ListItemIcon>
                      <ListItemText primary="Close" />
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
              <div className="container">
                {!selectedOptionValue && <Home />}
                {selectedOptionValue === 1 && <FrmQuickIncome />}
                {selectedOptionValue === 2 && <FrmGlDetail />}
                {selectedOptionValue === 3 && <FrmGlActivity />}
                {selectedOptionValue === 4 && <FrmCorporateReport />}
              </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Home />
            </UnauthenticatedTemplate>
            <Footer />
          </div>
        } />
      </Routes>
    </MsalProvider>
  );
}

export default App;
